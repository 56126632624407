import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  prompt: "prompt",
  maxTokens: "maxTokens",
  maxMessagesPerDay: "maxMessagesPerDay",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.prompt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.prompt)).label(fieldNames.prompt),
    [fieldNames.maxTokens]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.maxTokens)).label(fieldNames.maxTokens),
    [fieldNames.maxMessagesPerDay]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.maxMessagesPerDay)).label(fieldNames.maxMessagesPerDay),
  });
