import DescriptionComponent from "components/DescriptionComponent";
import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "subtitle",
    Component: ({ param }) => `${param.subTitle}` || "Unknown",
  },

  {
    header: "description",
    Component: ({ param }) => <DescriptionComponent description={param.description} />,
  },
  {
    header: "calories",
    Component: ({ param }) => param.calories,
  },
  {
    header: "minutes",
    Component: ({ param }) => param.minutes,
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
