import WithErrorHandler from "components/WithErrorHandler";
import Login from "containers/Login";
import routes from "operations/routing/routes";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import "./App.scss";
import AppRoutes from "./AppRoutes";

const cookies = new Cookies();

const App = () => {
  const token = useSelector((state) => state.token);
  const cookieToken = cookies.get("accessToken");

  return (
    <WithErrorHandler>
      <Routes>
        <Route path={routes.login} element={<Login />} />
        <Route path={"*"} element={token?.data && cookieToken ? <AppRoutes /> : <Navigate to={"/login"} />} />
      </Routes>
    </WithErrorHandler>
  );
};

export default App;
