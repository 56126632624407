import { yupResolver } from "@hookform/resolvers/yup";
// import FieldText from "components/FieldText";
import IngredientsSelector from "containers/Ingredients/IngredientsSelector";
import api from "operations/network/api";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { apiCall } from "utils/apiHelper";
import { useQueryCustom } from "utils/useQueryCustom";
import { fieldNames, modalValidation } from "./Add/validation";
import FieldSelect from "components/FieldSelect";

const IngredientModal = ({ handleClose, show, control, ingredients, setIngredients }) => {
  const [isIngredientSelected, setIsIngredientSelected] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedIngredient, setSelectedIngredient] = useState();
  const [fieldSelectOptions, setFieldSelectOptions] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(modalValidation),
  });

  const watchedSize = watch(fieldNames.size);
  const watchedGrams = watch(fieldNames.grams);

  const onSubmit = (data) => {
    const values = getValues();
    const newIngredient = {
      name: values.name,
      size: values.size,
      unit: selectedUnit?.value,
      ingredientId: dataById?.data?.data?._id,
      grams: watchedGrams,
    };

    // Add the new ingredient to the ingredients array
    setIngredients([...ingredients, newIngredient]);
    setSelectedIngredient();
    setIsIngredientSelected(false);
    setSelectedUnit();
    setFieldSelectOptions([]);
    setSelectedSize();

    reset({ name: "", size: "", unit: "", grams: "" });
    handleClose();
  };

  const closeModal = () => {
    setSelectedIngredient();
    setIsIngredientSelected(false);
    setSelectedUnit();
    setFieldSelectOptions([]);
    setSelectedSize();

    reset({ name: "", size: "", unit: "", grams: "" });
    handleClose();
  };

  // ----------------------------------------------------------------------------- Get data by id
  const onSuccessDataById = ({ data }) => {
    let params = { ...data?.data };
    reset({
      ...params,
    });
    const newOptions = params.servingSizes.map((item) => ({
      value: item.unit,
      label: item.unit,
    }));
    setFieldSelectOptions(newOptions);
  };

  const { data: dataById } = useQueryCustom({
    name: `getById_ing`,
    url: () => apiCall("get", api["ingredients"].get + "/" + selectedIngredient?.value),
    onSuccess: onSuccessDataById,
    enabled: !!isIngredientSelected,
  });

  useEffect(() => {
    if (selectedIngredient) {
      setIsIngredientSelected(true);
    }
  }, [selectedIngredient]);

  useEffect(() => {
    if (selectedUnit) {
      const selectedServingSize = dataById.data.data.servingSizes.find((item) => item.unit === selectedUnit.value);
      setValue(fieldNames.size, selectedServingSize.size);
      setValue(fieldNames.grams, +(selectedServingSize.size * selectedServingSize.grams).toFixed(2));
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (watchedSize && selectedUnit) {
      const selectedServingSize = dataById.data.data.servingSizes.find((item) => item.unit === selectedUnit.value);
      setValue(fieldNames.grams, +(watchedSize * selectedServingSize.grams).toFixed(2));
    }
  }, [watchedSize, selectedUnit]);

  return (
    <Modal show={show} onHide={closeModal} backdrop="static" dialogClassName="modal-780" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>New Ingredient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isIngredientSelected ? (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="mt-5">
              <div className="col-12">
                <div className="basic-form">
                  {selectedUnit ? (
                    <>
                      <Form.Group controlId="formBasicName" className="mb-4">
                        <Form.Label>{fieldNames.name}</Form.Label>
                        <Form.Control {...register(fieldNames.name)} placeholder={fieldNames.name} disabled />
                      </Form.Group>
                      <FieldSelect
                        {...{
                          control,
                          require: true,
                          register,
                          name: fieldNames.unit,
                          label: "selectUnit",
                          options: fieldSelectOptions,
                          setState: setSelectedUnit,
                        }}
                      />
                      <Form.Group controlId="formBasicSize" className="mb-4">
                        <Form.Label>{fieldNames.size}</Form.Label>
                        <Form.Control {...register(fieldNames.size)} placeholder={fieldNames.size} />
                      </Form.Group>
                      <Form.Group controlId="formBasicGrams" className="mb-4">
                        <Form.Label>{fieldNames.grams}</Form.Label>
                        <Form.Control {...register(fieldNames.grams)} placeholder={fieldNames.grams} disabled />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="formBasicName" className="mb-4">
                        <Form.Label>{fieldNames.name}</Form.Label>
                        <Form.Control {...register(fieldNames.name)} placeholder={fieldNames.name} disabled />
                      </Form.Group>

                      <FieldSelect
                        {...{
                          control,
                          require: true,
                          register,
                          name: fieldNames.unit,
                          label: "selectUnit",
                          options: fieldSelectOptions,
                          setState: setSelectedUnit,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <Button variant="primary" type="submit">
              Save Ingredient
            </Button>
          </form>
        ) : (
          <IngredientsSelector {...{ name: "ingredient", label: "ingredient", setState: setSelectedIngredient, control }} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default IngredientModal;
