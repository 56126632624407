import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  goal: "goal",
  question: "question",
  answer: "answer",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.goal]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.goal)).label(fieldNames.goal),
    [fieldNames.question]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.question)).label(fieldNames.question),
    [fieldNames.answer]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.answer)).label(fieldNames.answer),
  });
