import FieldText from "components/FieldText";
import ServingUnitSelector from "containers/ServingUnit/ServingUnitSelector";
import { Children } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ServingSizes = ({ fieldNames, control, errors, register }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldNames.servingSizes,
  });

  const addServingSize = () => append({ size: 1, unit: "", grams: "" });

  const renderServingSizes = () =>
    Children.toArray(
      fields.map((item, index) => (
        <Row className="translation-row" key={item.id}>
          {index >= 0 && (
            <>
              <Col lg={10}>
                <h5>{`${t("servingSizes")} ${index + 1}`}</h5>
              </Col>
              <Col lg={2}>
                <Button className="d-flex align-items-center btn-danger" size="sm" onClick={() => remove(index)}>
                  {t("remove")}
                </Button>
              </Col>
              <Col lg={12}>
                <hr style={{ marginBottom: 0 }} />
              </Col>
            </>
          )}
          <FieldText
            register={register}
            label={fieldNames.size}
            require={true}
            name={`${fieldNames.servingSizes}[${index}].size`}
            placeholder="size"
            error={errors.servingSizes?.[index]?.size}
            control={control}
            type="number"
          />
          <ServingUnitSelector
            {...{
              name: `${fieldNames.servingSizes}[${index}].unit`,
              label: fieldNames.unit,
              require: true,
              control,
              errors: errors.servingSizes?.[index]?.unit,
            }}
          />

          <FieldText
            label={fieldNames.grams}
            register={register}
            require={true}
            name={`${fieldNames.servingSizes}[${index}].grams`}
            placeholder="grams"
            error={errors.servingSizes?.[index]?.grams}
            control={control}
          />
        </Row>
      ))
    );

  return (
    <div className="col-12 border p-4 mt-4">
      <Row className="b-1">
        <Col lg={6}>
          <h2>{t("servingSizes")}</h2>
        </Col>
        <Col lg={6} className="d-flex justify-content-end">
          <Button onClick={addServingSize} className="d-flex align-items-center" size="sm">
            <div className="gallery-add-title">{t("add")}</div>
            <div className="ml-1">+</div>
          </Button>
        </Col>
        {errors.servingSizes && <p className="error-text mt-1">{errors?.servingSizes?.message}</p>}
      </Row>
      <div className="mt-5 col-12">
        <FieldText label={fieldNames.size} name={`${fieldNames.servingSizes}[${0}].size`} placeholder="1" type="number" disabled={true} />
        <FieldText label={fieldNames.unit} name={`${fieldNames.servingSizes}[${0}].unit`} placeholder="grams" disabled={true} />

        <FieldText label={fieldNames.grams} name={`${fieldNames.servingSizes}[${0}].grams`} placeholder="100" disabled={true} />
        <Controller name={fieldNames.servingSizes} control={control} render={renderServingSizes} />
      </div>
    </div>
  );
};

export default ServingSizes;
