export default {
  home: `/home`,
  login: `/login`,
  verify: `/verify`,
  dashboard: "/dashboard",
  file: {
    base: `/file`,
    list: `/file/list`,
    add: `/file/add`,
    edit: `/file/edit`,
  },
  admin: {
    base: `/admin`,
    all: "/admin/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  cardio: {
    base: `/cardio`,
    all: "/cardio/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  strength: {
    base: `/strength`,
    all: "/strength/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  recipeCategory: {
    base: `/recipeCategory`,
    all: "/recipeCategory/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  servingUnit: {
    base: `/servingUnit`,
    all: "/servingUnit/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  guideCategory: {
    base: `/guideCategory`,
    all: "/guideCategory/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  aiQa: {
    base: `/aiQa`,
    all: "/aiQa/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  ai: {
    base: `/setting`,
    all: "/setting/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  ingredients: {
    base: `/ingredients`,
    all: "/ingredients/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  recipes: {
    base: `/recipes`,
    all: "/recipes/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  guides: {
    base: `/guides`,
    all: "/guides/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  premiumPlan: {
    base: `/premiumPlan`,
    all: "/premiumPlan/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  users: {
    base: `/users`,
    all: "/users/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  subscriptions: {
    base: `/subscriptions`,
    all: "/subscriptions/*",
    list: `/list`,
    add: `/add`,
    edit: `/edit`,
  },
  media: {
    base: "/media",
    all: "/media/*",
    list: "/list",
    folder: {
      base: "/media/folder",
      all: "/media/folder/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
    },
    gallery: {
      base: "/media/gallery",
      all: "/media/gallery/*",
      list: "/list",
      add: "/add",
      edit: "/edit",
      folderName: "/media/gallery/:folderName",
    },
  },
};
