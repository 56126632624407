import * as yup from "yup";
import { ERRORS } from "enumerations";

export const fieldNames = {
  name: "name",
  defaultServingSize: "defaultServingSize",
  description: "description",
  isPremium: "isPremium",
  categoryId: "categoryId",
  mainName: "mainName",
  calories: "calories",
  fat: "fat",
  carbohydrates: "carbohydrates",
  proteins: "proteins",
  steps: "steps",
  ingredients: "ingredients",
  tags: "tags",
  image: "image",
  size: "size",
  unit: "unit",
  grams: "grams",

  nameAr: "nameAr",
  nameNl: "nameNl",
  nameFr: "nameFr",
  nameDe: "nameDe",
  nameHi: "nameHi",
  nameId: "nameId",
  nameIt: "nameIt",
  nameRu: "nameRu",
  nameEs: "nameEs",
  nameTr: "nameTr",

  descAr: "descAr",
  descNl: "descNl",
  descFr: "descFr",
  descDe: "descDe",
  descHi: "descHi",
  descId: "descId",
  descIt: "descIt",
  descRu: "descRu",
  descEs: "descEs",
  descTr: "descTr",

  stepsAr: "stepsAr",
  stepsNl: "stepsNl",
  stepsFr: "stepsFr",
  stepsDe: "stepsDe",
  stepsHi: "stepsHi",
  stepsId: "stepsId",
  stepsIt: "stepsIt",
  stepsRu: "stepsRu",
  stepsEs: "stepsEs",
  stepsTr: "stepsTr",

  tagsAr: "tagsAr",
  tagsNl: "tagsNl",
  tagsFr: "tagsFr",
  tagsDe: "tagsDe",
  tagsHi: "tagsHi",
  tagsId: "tagsId",
  tagsIt: "tagsIt",
  tagsRu: "tagsRu",
  tagsEs: "tagsEs",
  tagsTr: "tagsTr",
};

const ingredients = yup.object({
  name: yup.string().required(ERRORS.pleaseEnterYour("name")),
  servingSize: yup.number().required(ERRORS.pleaseEnterYour("serving size")),
  servingUnit: yup.string().required(ERRORS.pleaseEnterYour("serving unit")),
  calories: yup.number().required(ERRORS.pleaseEnterYour("calories")),
  fat: yup.number().required(ERRORS.pleaseEnterYour("fat")),
  carbohydrates: yup.number().required(ERRORS.pleaseEnterYour("carbohydrates")),
  proteins: yup.number().required(ERRORS.pleaseEnterYour("proteins")),
});

export const validation = () =>
  yup.object().shape({
    [fieldNames.mainName]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.mainName)).label(fieldNames.mainName),
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
    [fieldNames.defaultServingSize]: yup
      .number()
      .required(ERRORS.pleaseEnterYour(fieldNames.defaultServingSize))
      .label(fieldNames.defaultServingSize),
    [fieldNames.steps]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour("step")).label(fieldNames.steps)),
    // [fieldNames.ingredients]: yup.array().of(ingredients),
    [fieldNames.categoryId]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.categoryId)).label(fieldNames.categoryId),
    [fieldNames.tags]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour("tag")).label(fieldNames.tags),
      })
    ),
    [fieldNames.isPremium]: yup.boolean().required(ERRORS.pleaseEnterYour(fieldNames.isPremium)).label(fieldNames.isPremium),
    [fieldNames.image]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed();
      }
    }),
    [fieldNames.nameAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameAr)).label(fieldNames.nameAr),
    [fieldNames.nameNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameNl)).label(fieldNames.nameNl),
    [fieldNames.nameFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameFr)).label(fieldNames.nameFr),
    [fieldNames.nameDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameDe)).label(fieldNames.nameDe),
    [fieldNames.nameHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameHi)).label(fieldNames.nameHi),
    [fieldNames.nameId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameId)).label(fieldNames.nameId),
    [fieldNames.nameIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameIt)).label(fieldNames.nameIt),
    [fieldNames.nameRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameRu)).label(fieldNames.nameRu),
    [fieldNames.nameEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameEs)).label(fieldNames.nameEs),
    [fieldNames.nameTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameTr)).label(fieldNames.nameTr),

    [fieldNames.descAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descAr)).label(fieldNames.descAr),
    [fieldNames.descNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descNl)).label(fieldNames.descNl),
    [fieldNames.descFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descFr)).label(fieldNames.descFr),
    [fieldNames.descDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descDe)).label(fieldNames.descDe),
    [fieldNames.descHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descHi)).label(fieldNames.descHi),
    [fieldNames.descId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descId)).label(fieldNames.descId),
    [fieldNames.descIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descIt)).label(fieldNames.descIt),
    [fieldNames.descRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descRu)).label(fieldNames.descRu),
    [fieldNames.descEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descEs)).label(fieldNames.descEs),
    [fieldNames.descTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descTr)).label(fieldNames.descTr),

    [fieldNames.stepsAr]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsAr)).label(fieldNames.stepsAr)),
    [fieldNames.stepsNl]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsNl)).label(fieldNames.stepsNl)),
    [fieldNames.stepsFr]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsFr)).label(fieldNames.stepsFr)),
    [fieldNames.stepsDe]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsDe)).label(fieldNames.stepsDe)),
    [fieldNames.stepsHi]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsHi)).label(fieldNames.stepsHi)),
    [fieldNames.stepsId]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsId)).label(fieldNames.stepsId)),
    [fieldNames.stepsIt]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsIt)).label(fieldNames.stepsIt)),
    [fieldNames.stepsRu]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsRu)).label(fieldNames.stepsRu)),
    [fieldNames.stepsEs]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsEs)).label(fieldNames.stepsEs)),
    [fieldNames.stepsTr]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stepsTr)).label(fieldNames.stepsTr)),

    [fieldNames.tagsAr]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsAr)).label(fieldNames.tagsAr),
      })
    ),
    [fieldNames.tagsNl]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsNl)).label(fieldNames.tagsNl),
      })
    ),
    [fieldNames.tagsFr]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsFr)).label(fieldNames.tagsFr),
      })
    ),
    [fieldNames.tagsDe]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsDe)).label(fieldNames.tagsDe),
      })
    ),
    [fieldNames.tagsHi]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsHi)).label(fieldNames.tagsHi),
      })
    ),
    [fieldNames.tagsId]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsId)).label(fieldNames.tagsId),
      })
    ),
    [fieldNames.tagsIt]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsIt)).label(fieldNames.tagsIt),
      })
    ),
    [fieldNames.tagsRu]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsRu)).label(fieldNames.tagsRu),
      })
    ),
    [fieldNames.tagsEs]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsEs)).label(fieldNames.tagsEs),
      })
    ),
    [fieldNames.tagsTr]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.tagsTr)).label(fieldNames.tagsTr),
      })
    ),
  });

export const modalValidation = yup.object().shape({
  name: yup.string().required(ERRORS.pleaseEnterYour("name")).label(fieldNames.name),
  size: yup.number().optional().label(fieldNames.size),
  grams: yup.number().optional().label(fieldNames.grams),
  unit: yup.object().optional().label(fieldNames.unit),
});
