import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "name",
    Component: ({ param }) => `${param.name || "Unknown"}`,
  },

  {
    header: "calories",
    Component: ({ param }) => param.calories || 0,
  },
  {
    header: "fat",
    Component: ({ param }) => param.fat || 0,
  },
  {
    header: "carbohydrates",
    Component: ({ param }) => param.carbohydrates || 0,
  },
  {
    header: "proteins",
    Component: ({ param }) => param.proteins || 0,
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
