import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, withOutEdit: true }} />,
  },
  {
    header: "email",
    Component: ({ param }) => `${param.user?.email}` || "Unknown",
  },
  {
    header: "planName",
    Component: ({ param }) => `${param.plan?.name}` || "Unknown",
  },

  {
    header: "startedAt",
    Component: ({ param }) => isoToDate(param.startedAt),
  },
  {
    header: "endedAt",
    Component: ({ param }) => isoToDate(param.endedAt),
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
