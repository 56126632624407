import Navbar from "components/Navbar";
import Spinner from "components/Spinner";
import routing from "operations/routing";
import { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Footer from "components/Footer";
import ProtectedRoute from "components/ProtectedRoute";
import Sidebar from "components/Sidebar";
import SplashScreen from "components/SplashScreen";
import toastify from "lib/toastify";
import { useDispatch, useSelector } from "react-redux";

const AppRoutes = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenState = useSelector((state) => state.token);
  const adminState = useSelector((state) => state.admin);

  let auth = tokenState?.data;

  // && !adminState?.isFetching && !tokenState.isFetching
  useEffect(() => {
    // if (!auth && !tokenState.isFetching) {
    if (!auth) {
      // navigate(routes.login);
      toastify.success({ title: "warning,appRoutes_33" });

      // dispatch(actionUser.logout());
    }

    // if (!adminState?.isFetching && !adminState?.data) {
    //   navigate(routes.login);
    //   // dispatch(actionUser.logout());
    // }
  }, [adminState, tokenState]);

  return auth ? (
    <>
      <SplashScreen show={!tokenState?.data} />
      {tokenState?.data ? (
        <div className="container-scroller">
          <Navbar />

          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    {routing.map((x) => (
                      <Route
                        key={x.path}
                        path={x.path}
                        exact={x?.exact}
                        element={
                          // x?.permission && adminState.data.operation.permissions !== true ? (
                          <ProtectedRoute isAllowed={true}>{x.element}</ProtectedRoute>
                        }
                      />
                    ))}
                    {/* <Route index element={<Navigate to={"/login"} />} /> */}
                  </Routes>
                </Suspense>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  ) : (
    <></>
  );
};

export default AppRoutes;
