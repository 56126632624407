export let requestErrors = { message: "Something was wrong. Try again", type: "warning" };

export const changeError = (props) => {
  if (props.message) requestErrors = { ...requestErrors, message: props.message, type: "warning" };

  let data = props?.response?.data;

  if (!data) return requestErrors;
  let convertFormat = requestErrors;
  let errors = data?.error;
  let message = data?.message;
  // const hasStatus = [0, 1, 200, 201, 204, 401, 403, 404, 405, 406, 409, 415, 422, 440, 498, 500]
  if (errors)
    message?.map?.((error, index) => {
      // let key = Object.keys(error)[0];
      // let value = Object.values(error)[0];
      convertFormat = { ...convertFormat, [index]: { message: error } };
    });
  else if (message) convertFormat = { ...convertFormat, message: message };

  if (Object.keys(convertFormat).length) return convertFormat;
  else return requestErrors;
};
