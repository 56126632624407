import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";

export const apiCall = async (method, url, params) => {
  const axios = axiosClient();

  switch (method) {
    case "get":
      return await axios.get(url, { params: { ...params } });
    case "patch":
      return await axios.patch(url, params);
    case "put":
      return await axios.put(url, params);
    case "post":
      return await axios.post(url, params);
    case "delete":
      return await axios.delete(url);
    default:
      throw new Error("Invalid method");
  }
};

export const onSuccessMutation = (message, isNavigate, navigate, navigateUrl) => {
  toastify.success({ title: message || "Success" });
  if (isNavigate) {
    navigate(navigateUrl);
  }
};
