import network from "lib/network";
import api from "operations/network/api";
import { all, call, delay, put, takeEvery } from "redux-saga/effects";
import actionToken from "redux/store/token/action";
import { changeError } from "utils/errors";
import actionTypes from "../actionTypes";
import action from "../store/action";
// /store/action
import Cookies from "universal-cookie";

import actionUser from "redux/store/admin/action";

function* postSaga({ params }) {
  try {
    const res = yield call(network.post, { url: api.auth.login, params });
    const cookies = new Cookies();

    if (res?.data) {
      yield put(actionToken.receive(res.data));
      yield put(action.receive(res.data));
      yield cookies.set("accessToken", res.data.accessToken, { path: "/" });
      yield cookies.set("role", res.data.role, { path: "/" });
    } else {
      yield put(action.receive("verify"));
    }

    yield put(actionUser.request());

    yield delay(2000);
    yield put(action.initial());
  } catch (error) {
    yield put(action.failure(changeError(error)));
    yield delay(2000);
    yield put(action.initial());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LOGIN_REQUEST, postSaga)]);
}
