import { useEffect, useRef } from 'react';
import axiosClient from 'lib/axiosConfig';
import api from 'operations/network/api';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import compareTranslate from 'utils/compareTranslate';
import { useQueryCustom } from 'utils/useQueryCustom';
import { API_NAME } from './enum';

const LoadingIndicator = () => {
    return (
        <div className="mx-2">
            <Spinner animation="grow" size="sm" />
        </div>
    );
};

export const convertCategoriesData = (data, { withAll, withParent, t } = {}) => {
    const param = data?.map?.((x) => ({
        label: (
            <div className="flex align-items-center justify-items-center ">
                <div className="ml-4 mt-1 d-flex align-items-center">{` ${x?.parent?.name ? `${x?.parent?.name} -> ` : ''} ${x?.name}`}</div>
            </div>
        ),
        value: x?._id,
    }));

    if (withAll)
        param?.unshift?.({
            label: (
                <div className="flex align-items-center justify-items-center">
                    <div className="ml-4 mt-1 d-flex align-items-center">{t('all')}</div>
                </div>
            ),
            value: 'all',
        });

    return param;
};

export const convertCategoryData = (x, { withParent } = {}) => ({
    label: (
        <div className="flex align-items-center justify-items-center">
            <div className="ml-4 mt-1 d-flex align-items-center">{` ${x?.parent?.name ? `${x?.parent?.name} -> ` : ''} ${x?.name}`}</div>
        </div>
    ),
    value: x?._id,
});

const IngredientsSelector = ({
    name,
    control,
    label,
    valid,
    register,
    errors,
    require,
    options,
    setState,
    withAll,
    setParams,
    withParent,
    isDisabled,
    ...other
}) => {
    const { t } = useTranslation();

    const getting = async (params) => {
        const { searchText, limit, page } = params;
        return await axiosClient().get(api[API_NAME].list, {
            params: {
                limit: limit || 30,
                searchText,
                page,
                ...(setParams && setParams),
            },
        });
    };
    // ------------------------------------------------------- query Get
    const {
        data: { data = {} } = {},
        error,
        isError,
        isLoading,
        refetch,
    } = useQueryCustom({
        name: `${API_NAME}_get`,
        url: getting,
    });

    const searchRef = useRef();

    const loadOptions = async (inputValue, callback) =>
        new Promise((resolve, reject) => {
            if (searchRef.current) clearTimeout(searchRef.current);
            // if (!inputValue) return callback()
            const time = setTimeout(async () => {
                const params = {
                    searchText: inputValue,
                    limit: 20,
                    page: 1,
                };
                await getting(params).then((x) => {
                    resolve(callback(convertCategoriesData(x?.data?.data?.result, { withAll, t })));
                });
            }, 1000);
            searchRef.current = time;
        });

    const dataForSelectBox = useMemo(() => {
        return convertCategoriesData(data?.data?.result, { withAll, withParent, t });
    }, [data]);
    let newRegister = register ? register(name) : {};

    useEffect(() => {
        refetch();
    }, [setParams]);

    return (
        <>
            <Form.Group className="form-group ">
                {label ? (
                    <div className={'d-flex justify-content-between'}>
                        <label htmlFor={name}>
                            {compareTranslate(t, label)}
                            {require ? ' *' : ''}
                        </label>
                        {errors ? <div className={`error-text `}>{compareTranslate(t, errors[name]?.message)}</div> : ''}
                    </div>
                ) : (
                    ''
                )}
                <Controller
                    control={control}
                    ref={newRegister}
                    name={name}
                    render={({ field: { onChange, value } }) => {
                        function handleChange(params) {
                            onChange?.(params);
                            setState?.(params);
                        }
                        return (
                            <AsyncSelect
                                className="custom-selector"
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions={dataForSelectBox}
                                onChange={handleChange}
                                components={{ LoadingIndicator }}
                                classNamePrefix="select"
                                // isMulti
                                styles={{
                                    input: (base) => ({
                                        ...base,
                                        'input:focus': {
                                            boxShadow: 'none',
                                        },
                                    }),
                                    control: (styles) => ({
                                        ...styles,
                                        borderColor: error ? 'red' : styles.borderColor,
                                    }),
                                }}
                                value={value}
                                isDisabled={isDisabled || isLoading}
                                {...other}
                            />
                        );
                    }}
                />
                {/* :
      } */}
            </Form.Group>
        </>
    );
};
export default IngredientsSelector;
