import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useMemo, useState } from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldText from "components/FieldText";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall, onSuccessMutation } from "utils/apiHelper";
import { createAddButton } from "utils/createAddButton";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";

const Add = () => {
  let { id } = useParams();
  const URL_ID = id ? id : "";
  const [isSubtitleThere, setIsSubtitleThere] = useState(false);

  const { t } = useTranslation();

  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const navigation = useNavigate();
  const updateOrCreate = URL_ID
    ? (params) => apiCall("put", api[API_NAME].base + "/" + URL_ID, params)
    : (params) => apiCall("post", api[API_NAME].base, params);
  // ----------------------------------------------------------------------------- Form initial
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validation(isSubtitleThere)),
    mode: "onSubmit",
  });
  console.log({ errors });
  // ----------------------------------------------------------------------------- Create or update function (base on URL_ID)
  const { isLoading, mutate } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: () => onSuccessMutation("Success", true, navigation, newRoute.base),
  });

  // ----------------------------------------------------------------------------- Get data by id
  const onSuccessDataById = ({ data }) => {
    let params = { ...data?.data };
    if (params?.subTitle) {
      setIsSubtitleThere(true);
    }
    reset({
      ...params,
      subAr: params.subTitleTranslations.ar,
      subNl: params.subTitleTranslations.nl,
      subFr: params.subTitleTranslations.fr,
      subDe: params.subTitleTranslations.de,
      subHi: params.subTitleTranslations.hi,
      subId: params.subTitleTranslations.id,
      subIt: params.subTitleTranslations.it,
      subRu: params.subTitleTranslations.ru,
      subEs: params.subTitleTranslations.es,
      subTr: params.subTitleTranslations.tr,

      descAr: params.descriptionTranslations.ar,
      descNl: params.descriptionTranslations.nl,
      descFr: params.descriptionTranslations.fr,
      descDe: params.descriptionTranslations.de,
      descHi: params.descriptionTranslations.hi,
      descId: params.descriptionTranslations.id,
      descIt: params.descriptionTranslations.it,
      descRu: params.descriptionTranslations.ru,
      descEs: params.descriptionTranslations.es,
      descTr: params.descriptionTranslations.tr,
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: () => apiCall("get", api[API_NAME].get + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();
    const requestData = {
      subTitle: values.subTitle,
      description: values.description,
      calories: values.calories,
      minutes: values.minutes,
      subTitleTranslations: {
        ar: values.subAr || "",
        nl: values.subNl || "",
        fr: values.subFr || "",
        de: values.subDe || "",
        hi: values.subHi || "",
        id: values.subId || "",
        it: values.subIt || "",
        ru: values.subRu || "",
        es: values.subEs || "",
        tr: values.subTr || "",
      },
      descriptionTranslations: {
        ar: values.descAr,
        nl: values.descNl,
        fr: values.descFr,
        de: values.descDe,
        hi: values.descHi,
        id: values.descId,
        it: values.descIt,
        ru: values.descRu,
        es: values.descEs,
        tr: values.descTr,
      },
    };
    console.log("🚀 ~ onSubmit ~ requestData:", requestData);
    mutate(requestData);
  };

  const handleSubtitleChange = (e) => {
    if (!isSubtitleThere) {
      setIsSubtitleThere(true);
    } else if (!e.target.value && isSubtitleThere) {
      setIsSubtitleThere(false);
    }
  };

  console.log({ value: getValues() });

  return (
    <div>
      <PageTitle
        title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
        button={createAddButton({
          title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
          link: newRoute.base + newRoute.list,
        })}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <div className={"d-flex justify-content-between"}>
                        <label htmlFor={fieldNames.subtitle}>{fieldNames.subtitle}</label>
                      </div>
                      <Form.Control
                        id={fieldNames.subtitle}
                        name={fieldNames.subtitle}
                        type="text"
                        placeholder={fieldNames.subtitle}
                        onChange={(e) => {
                          handleSubtitleChange(e); // Your custom onChange handler
                          register(fieldNames.subtitle).onChange(e); // React Hook Form's onChange handler
                        }}
                        className="mb-4"
                        {...register(fieldNames.subtitle, { onChange: (e) => handleSubtitleChange(e) })}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.calories,
                          register,
                          placeholder: fieldNames.calories,
                          label: fieldNames.calories,
                          errors,
                          type: "number",
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.minutes,
                          register,
                          placeholder: fieldNames.minutes,
                          label: fieldNames.minutes,
                          errors,
                          type: "number",
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.description,
                          register,
                          placeholder: fieldNames.description,
                          label: fieldNames.description,
                          errors,
                          isTextArea: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("translations")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <Accordion defaultActiveKey="0">
                        {isSubtitleThere && (
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>{t("subtitleTranslations")}</Accordion.Header>
                            <Accordion.Body>
                              <>
                                <div className="b-1">
                                  <h4 className="mb-4"></h4>
                                </div>
                                <FieldText
                                  {...{
                                    name: fieldNames.subAr,
                                    register,
                                    placeholder: "Ar",
                                    label: "Ar",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subNl,
                                    register,
                                    placeholder: "Nl",
                                    label: "Nl",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subFr,
                                    register,
                                    placeholder: "Fr",
                                    label: "Fr",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subDe,
                                    register,
                                    placeholder: "De",
                                    label: "De",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subHi,
                                    register,
                                    placeholder: "Hi",
                                    label: "Hi",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subId,
                                    register,
                                    placeholder: "Id",
                                    label: "Id",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subIt,
                                    register,
                                    placeholder: "It",
                                    label: "It",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subRu,
                                    register,
                                    placeholder: "Ru",
                                    label: "Ru",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subEs,
                                    register,
                                    placeholder: "Es",
                                    label: "Es",
                                    errors,
                                  }}
                                />
                                <FieldText
                                  {...{
                                    name: fieldNames.subTr,
                                    register,
                                    placeholder: "Tr",
                                    label: "Tr",
                                    errors,
                                  }}
                                />
                              </>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>{t("descriptionTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descAr,
                                register,
                                placeholder: "Ar",
                                label: "Ar",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descNl,
                                register,
                                placeholder: "Nl",
                                label: "Nl",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descFr,
                                register,
                                placeholder: "Fr",
                                label: "Fr",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descDe,
                                register,
                                placeholder: "De",
                                label: "De",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descHi,
                                register,
                                placeholder: "Hi",
                                label: "Hi",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descId,
                                register,
                                placeholder: "Id",
                                label: "Id",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descIt,
                                register,
                                placeholder: "It",
                                label: "It",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descRu,
                                register,
                                placeholder: "Ru",
                                label: "Ru",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descEs,
                                register,
                                placeholder: "Es",
                                label: "Es",
                                errors,
                                isTextArea: true,
                              }}
                            />
                            <FieldText
                              require
                              {...{
                                name: fieldNames.descTr,
                                register,
                                placeholder: "Tr",
                                label: "Tr",
                                errors,
                                isTextArea: true,
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
                {/* ------------------------------description ------------------------------------ */}
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="row sticky-position">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
