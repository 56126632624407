// import jwt from "jsonwebtoken";
import { decodeToken } from "react-jwt";
import Cookies from "universal-cookie/es6";

// export const decode = token => {
//     return jwt.decode(token, { complete: true });
// };
const cookies = new Cookies();

export const decodedToken = (token) => {
  const tokenDecoded = decodeToken(token);
  return tokenDecoded;
  // .payload.exp
};

export function removeTokens() {
  cookies.remove("accessToken", { path: "/" });
  cookies.remove("refreshToken", { path: "/" });
}

export function getAccessToken() {
  return cookies.get("accessToken");
}

export function getRefreshToken() {
  return cookies.get("refreshToken");
}

export function getLanguage() {
  return cookies.get("language");
}

export function tokenMsAge(token) {
  const dateNow = Date.now() / 1000;
  let second = 0;
  let expTime = decodedToken(token)?.exp;
  if (expTime) second = Math.floor(expTime - dateNow);
  // if (expTime) second = expTime;

  return {
    s: second,
    ms: second * 1000,
  };
}
