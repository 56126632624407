import DescriptionComponent from "components/DescriptionComponent";
import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "description",
    Component: ({ param }) => <DescriptionComponent description={param.description} />,
  },
  {
    header: "numberOfSets",
    Component: ({ param }) => param.numberOfSets,
  },
  {
    header: "repetitionPerSets",
    Component: ({ param }) => param.repetitionPerSets,
  },
  {
    header: "weightPerRepetition",
    Component: ({ param }) => param.weightPerRepetition,
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
