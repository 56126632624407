import DescriptionComponent from "components/DescriptionComponent";
import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "video",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <video src={param.video} controls style={{ maxWidth: "150px", borderRadius: "7px" }} />
          </span>
        </>
      );
    },
  },
  {
    header: "image",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <img src={param?.image} className="image-fluid" />
          </span>
        </>
      );
    },
  },

  {
    header: "equipments",
    Component: ({ param }) => {
      return (
        <>
          {param.equipments.map((equipments, index) => (
            <p key={index}>{equipments}</p>
          ))}
        </>
      );
    },
  },
  {
    header: "name",
    Component: ({ param }) => param.name,
  },
  {
    header: "description",
    Component: ({ param }) => <DescriptionComponent description={param?.description} />,
  },
  {
    header: "isPremium",
    Component: ({ param }) => (param.isPremium ? <span class="badge badge-success">True</span> : <span class="badge badge-danger">False</span>),
  },
  {
    header: "content",
    Component: ({ param }) => {
      return (
        <>
          {param.content.map((item, index) => (
            <div>
              <DescriptionComponent description={item} key={index} />
              <br />
            </div>
          ))}
        </>
      );
    },
  },
  {
    header: "durationInMinutes",
    Component: ({ param }) => param.durationInMinutes,
  },
  {
    header: "guideType",
    Component: ({ param }) => param.guideType,
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
