import { SORT_OPTION, SORT_OPTION_OBJECT } from "enumerations";
import { Children, Fragment, useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationM from "../PaginationM";

import FieldDate from "components/FieldDate";
import TableSkeleton from "components/TableSkeleton";
import { useTranslation } from "react-i18next";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import { convertorArrayOption } from "utils/convertor";
import { TABLE_STATUS } from "./enum";

const TableComponent = ({
  data,
  tableOptions,
  QueryString,
  search,
  pagination,
  count,
  loading,
  actions,
  checkbox,
  status,
  dating,
  disables,
  excelButton,
  isHotel,
  disableFiltering,
}) => {
  disables = disables || {};
  const inputRef = useRef();
  const [t] = useTranslation();

  const { limit } = QueryString;

  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
  });

  const navigateUrl = (obj) => navigate(changeUrl(obj));

  //  ---------------------------------------------------------------------- checkBox
  const [checkedItems, setCheckedItems] = useState({}); // its has to come from the component

  const setStateData = checkbox?.setState || setCheckedItems;

  const stateData = checkbox?.state || checkedItems;

  const toggleCheckboxFun = (data) => {
    if (!!stateData[data.id]) {
      const { [data.id]: removing, ...prop } = stateData;
      setStateData(prop);
    } else setStateData((p) => ({ ...p, [data.id]: data }));
  };

  const toggleCheckboxFunAll = (e, data) => {
    let obj = {};
    // Function to toggle checkbox
    const toggleCheckbox = (selected) => {
      if (!e.target.checked) {
        // If checkbox is unchecked, remove item from obj
        delete obj[selected.id];
      } else {
        // If checkbox is checked, add item to obj
        obj[selected.id] = selected;
      }
    };
    // Loop through data array
    data.forEach((selected) => {
      toggleCheckbox(selected);
    });
    // Update stateData with the modified obj
    setStateData((p) => ({ ...p, ...obj }));
  };

  const selectedAllCheckbox = data?.filter((x) => stateData[x.id])?.length === data?.length;
  const isSelect = Object.values(stateData).filter((x) => x)?.length;
  let showing = pagination.activePage * limit;
  if (showing > count) showing = count;
  // if( !count &&count !==0)

  // ------------------------------------------ Search

  function cancelSearchHandler() {
    let value = inputRef.current.value;
    if (value) {
      delete QueryString["searchText"];
      changeUrl({});
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    const value = inputRef.current.value.trim();
    // Update URL based on search input value
    if (value) {
      navigate(changeUrl({ searchText: value }));
    } else {
      delete QueryString["searchText"];
      navigate(changeUrl({}));
    }
  }

  // ------------------------------------------------------- sorting
  const DEFAULT_STATUS = [{ label: "All", value: undefined }, ...(status || TABLE_STATUS)];
  const DEFAULT_STATUS_OBJECT = {};
  convertorArrayOption(DEFAULT_STATUS, DEFAULT_STATUS_OBJECT);
  // useMemo(() => convertorArrayOption(DEFAULT_STATUS, DEFAULT_STATUS_OBJECT), [status]);

  return (
    <div className="col-12 pb-5">
      <div className="card">
        {dating ? (
          <div className="d-flex">
            <div>
              <FieldDate
                {...{
                  label: "from",
                  onChange: (e) => navigateUrl({ from: new Date(e).toISOString() }),
                  selected: QueryString.from && new Date(QueryString.from),
                  placeholderText: t("selectDate"),
                  maxDate: new Date(),
                }}
              />
            </div>

            <div>
              <FieldDate
                {...{
                  label: "to",
                  onChange: (e) => navigateUrl({ to: new Date(e).toISOString() }),
                  selected: QueryString.to && new Date(QueryString.to),
                  startDate: QueryString.from && QueryString.from,
                  placeholderText: t("selectDate"),
                  minDate: QueryString.from && new Date(QueryString.from),
                  maxDate: new Date(),
                }}
              />
            </div>
            <div className="d-flex align-items-center">
              {dating?.buttons ? (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {t("exportExcel")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {dating?.buttons?.map((x) => {
                      return (
                        <Dropdown.Item onClick={x.onClick} className="py-2">
                          <i className="mdi mdi-download mr-2" />
                          {x?.title}
                        </Dropdown.Item>
                      );
                    })}

                    {/* <Dropdown.Item className="py-2"><i className="mdi mdi-download" />   {t("table")}</Dropdown.Item> */}
                    <Dropdown.Divider />
                    <Dropdown.Item className="py-2" eventKey="4">
                      {" "}
                      {t("exportExcel")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : dating?.button ? (
                <Button disabled={!QueryString.from || !QueryString.to} onClick={dating?.button?.onClick}>
                  <i className="mdi mdi-download" />
                  Download as an Excel
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        {excelButton?.show ? (
          <div className="d-flex">
            {" "}
            <Button disabled={excelButton?.disabled} onClick={excelButton?.onClick}>
              <i className="mdi mdi-download" /> Download as an Excel
            </Button>{" "}
          </div>
        ) : (
          ""
        )}

        <div className="justify-content-between d-flex flex-column align-items-center">
          <div className={"d-flex w-100 mt-4 justify-content-between mt-5"}>
            <div className="">
              {/* {!disables.all ? (
                <div className="filter-sort-wrapper d-flex mb-0">
                  {!disables?.action && actions?.onClick && isSelect && isSelect > 0 ? (
                    <Dropdown variant="primary">
                      <Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3">
                        <span>{t(isSelect)}</span> {t("action")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropDown-menu-custom">
                        <Dropdown.Header className="text-capitalize" header>
                          {t("anActionForAllTheSelect")}
                        </Dropdown.Header>
                        {Children.toArray(
                          DEFAULT_STATUS?.slice(1, DEFAULT_STATUS?.length)?.map((action) => (
                            <Dropdown.Item
                              key={action.label + "_ACTION"}
                              onClick={() => actions?.onClick(action)}
                              className="dropDown-item-custom text-capitalize"
                            >
                              {t(action?.label)}
                            </Dropdown.Item>
                          ))
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}

                  {count >= 10 ? (
                    <Dropdown variant="primary">
                      <Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3">
                        {t(`limitAuthor`, { author: limit })}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropDown-menu-custom">
                        <Dropdown.Header className="text-capitalize" header>
                          {"Select table limit"}
                        </Dropdown.Header>
                        {Children.toArray(
                          tableDisplayed.map((x) => (
                            <Dropdown.Item
                              key={x.label}
                              onClick={() => navigateUrl({ limit: x.value })}
                              className="dropDown-item-custom text-capitalize"
                              active={x.value === limit}
                            >
                              {t(x?.value)}
                            </Dropdown.Item>
                          ))
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}

                  {/*  ---------------------- filtering ------------------------------------------ */}
              {disableFiltering ? (
                ""
              ) : (
                <Dropdown variant="primary">
                  <Dropdown.Toggle id="dropdown-basic" className="btn-sm ">
                    {t(SORT_OPTION_OBJECT[QueryString.sort]?.label || "sortBy")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropDown-menu-custom">
                    <Dropdown.Header className="text-capitalize" header>
                      {t("sortBy")}
                    </Dropdown.Header>

                    {Children.toArray(
                      SORT_OPTION.map((sort) => (
                        <Dropdown.Item
                          onClick={() => navigateUrl({ sort: sort.value })}
                          className="dropDown-item-custom text-capitalize"
                          active={sort.value?.toLowerCase() === QueryString.sort?.toLowerCase()}
                        >
                          {t(sort?.label)}
                        </Dropdown.Item>
                      ))
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>

            {!disables.all && search ? (
              <div className="">
                <form className="input-group search-area" onSubmit={handleSearch}>
                  <input
                    type="text"
                    className="form-control custom-search-input"
                    placeholder={search.placeholder || "search ..."}
                    defaultValue={search.defaultValue}
                    ref={inputRef}
                  />
                  <div className="input-group-append">
                    <span className="btn btn-primary px-3 " onClick={handleSearch}>
                      <i className="mdi mdi-magnify"></i>
                    </span>
                  </div>
                </form>
                {QueryString.search && (
                  <div className="cancel-btn-wrapper" onClick={cancelSearchHandler}>
                    <i className="flaticon-034-cancel"></i>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="">
          {!data?.length ? (
            loading ? (
              <TableSkeleton />
            ) : (
              <div className="text-center h2 text-capitalize text-muted font-weight-normal mt-5">There is no data to show !</div>
            )
          ) : (
            <>
              <div className="col-lg-12 grid-margin stretch-card mt-3">
                <div id="table_wrapper" className="table-responsive pb-5">
                  <table id="example" className="table pb-5">
                    <thead>
                      <tr role="row">
                        {actions?.onClick
                          ? // <th className="width50 ">
                            //   <div className="custom-control custom-checkbox checkbox-success check-md  bs_exam_topper_all">
                            //     <input
                            //       type="checkbox"
                            //       className="custom-control-input"
                            //       id="checkAll"
                            //       required=""
                            //       onChange={(e) => {
                            //         toggleCheckboxFunAll(e, data);
                            //       }}
                            //       checked={selectedAllCheckbox}
                            //     />
                            //     <label className="custom-control-label" htmlFor="checkAll"></label>
                            //   </div>
                            // </th>
                            ""
                          : ""}

                        {tableOptions?.map(({ header, className, style }, i) => (
                          <th key={i} className={className} style={style}>
                            {t(header)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((d, i) => {
                        return (
                          <tr
                            key={`${d.id}`}
                            style={{
                              ...(d.orderColor && !isHotel && { backgroundColor: d.orderColor }),
                            }}
                          >
                            {actions?.onClick
                              ? // <td>
                                //   <div className="custom-control custom-checkbox checkbox-success check-md bs_exam_topper">
                                //     <input
                                //       type="checkbox"
                                //       className="custom-control-input"
                                //       id={"customCheckBox" + i}
                                //       required=""
                                //       checked={stateData[d.id]}
                                //       onClick={() => {
                                //         toggleCheckboxFun(d);
                                //       }}
                                //     />
                                //     <label className="custom-control-label" htmlFor={"customCheckBox" + i}></label>
                                //   </div>
                                // </td>
                                ""
                              : ""}
                            {tableOptions?.map(({ Component, className, style, title }, i) => {
                              let inFunc = typeof Da === "function";
                              let inObject = typeof Da === "object";
                              if (Component)
                                return (
                                  <Fragment key={i}>
                                    <td className={className} style={style}>
                                      <Component param={d} />
                                    </td>
                                  </Fragment>
                                );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <Row>
                {!disables.all ? (
                  <Col sm={6} md={6}>
                    {`${t("showing")} ${showing || 0} ${t("out_of")} ${count || 0} ${t("entries")}`}
                  </Col>
                ) : (
                  ""
                )}

                <Col sm={6} md={6}>
                  <div className="pagination-container">
                    <PaginationM {...pagination} onClick={(page) => navigateUrl({ page })} />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {/* {!data?.length ? loading ? <TableSkeleton /> : <div>There is no data to show!</div> : ""} */}
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
