import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Accordion, Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldText from "components/FieldText";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall, onSuccessMutation } from "utils/apiHelper";
import { createAddButton } from "utils/createAddButton";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";

const Add = () => {
  let { id } = useParams();
  const URL_ID = id ? id : "";

  const { t } = useTranslation();

  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const navigation = useNavigate();
  const updateOrCreate = URL_ID
    ? (params) => apiCall("put", api[API_NAME].base + "/" + URL_ID, params)
    : (params) => apiCall("post", api[API_NAME].base, params);
  // ----------------------------------------------------------------------------- Form initial
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Create or update function (base on URL_ID)
  const { isLoading, mutate } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: () => onSuccessMutation("Success", true, navigation, newRoute.base),
  });

  // ----------------------------------------------------------------------------- Get data by id
  const onSuccessDataById = ({ data }) => {
    let params = { ...data?.data };
    reset({
      ...params,
      nameAr: params.translations.ar,
      nameNl: params.translations.nl,
      nameFr: params.translations.fr,
      nameDe: params.translations.de,
      nameHi: params.translations.hi,
      nameId: params.translations.id,
      nameIt: params.translations.it,
      nameRu: params.translations.ru,
      nameEs: params.translations.es,
      nameTr: params.translations.tr,
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: () => apiCall("get", api[API_NAME].get + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();
    const requestData = {
      name: values.name,
      translations: {
        ar: values.nameAr,
        nl: values.nameNl,
        fr: values.nameFr,
        de: values.nameDe,
        hi: values.nameHi,
        id: values.nameId,
        it: values.nameIt,
        ru: values.nameRu,
        es: values.nameEs,
        tr: values.nameTr,
      },
    };
    mutate(requestData);
  };

  return (
    <div>
      <PageTitle
        title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
        button={createAddButton({
          title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
          link: newRoute.base + newRoute.list,
        })}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.name,
                          register,
                          placeholder: fieldNames.name,
                          label: fieldNames.name,
                          errors,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("translations")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t("nameTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameAr,
                                  register,
                                  placeholder: "Ar",
                                  label: "Ar",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameNl,
                                  register,
                                  placeholder: "Nl",
                                  label: "Nl",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameFr,
                                  register,
                                  placeholder: "Fr",
                                  label: "Fr",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameDe,
                                  register,
                                  placeholder: "De",
                                  label: "De",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameHi,
                                  register,
                                  placeholder: "Hi",
                                  label: "Hi",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameId,
                                  register,
                                  placeholder: "Id",
                                  label: "Id",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameIt,
                                  register,
                                  placeholder: "It",
                                  label: "It",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameRu,
                                  register,
                                  placeholder: "Ru",
                                  label: "Ru",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameEs,
                                  register,
                                  placeholder: "Es",
                                  label: "Es",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameTr,
                                  register,
                                  placeholder: "Tr",
                                  label: "Tr",
                                  errors,
                                }}
                              />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="row sticky-position">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
