import { all, call, delay, put, takeLatest } from "@redux-saga/core/effects";
// import actionGlobal from '../store/actions';
import network from "lib/network";

import api from "operations/network/api";
import actionUser from "redux/store/admin/action";
import Cookies from "universal-cookie/es6";
import { decodedToken, getAccessToken, getRefreshToken, tokenMsAge } from "utils/cookies";
import { changeError } from "utils/errors";
import action from "../store/accessToken/action";
import actionTypes from "../store/accessToken/actionTypes";

function getUserInfo() {
  // let userState = select(state => state.user);
  let refreshToken = getRefreshToken();
  let accessToken = getAccessToken();
  return decodedToken(accessToken || refreshToken);
}

let timesRequestError = 3;
function* getSaga({}) {
  let useInfo = getUserInfo();
  if (!useInfo?._id) return yield put(actionUser.logout());
  try {
    const res = yield call(network.post, {
      url: `${api.refreshToken}`,
      token: getRefreshToken(),
    });
    let accessToken = res.data.result.accessToken;
    // let refreshToken = res.data.refreshToken;
    const cookies = new Cookies();

    yield cookies.set("accessToken", accessToken, { path: "/" });

    yield put(actionUser.request());
    yield put(action.receive(res.data.result));
    yield put(action.accessTokenCheck());
  } catch (error) {
    timesRequestError--;

    if (timesRequestError) return yield put(action.request());
    yield put(action.failure(changeError(error?.response)));
    yield put(actionUser.logout());
    // yield delay(2000);
    // yield put(action.initial());
  }
}

export function* checkAuthAccessTokenTimeoutSaga({ expirationTime }) {
  expirationTime = Math.floor(expirationTime - 10000);
  yield delay(expirationTime);

  yield put(action.request());
}

export function* authCheckSaga() {
  const refreshToken = yield getRefreshToken();
  const accessToken = yield getAccessToken();

  if (refreshToken) {
    if (!accessToken) {
      yield put(action.request());
    } else {
      const expirationDate = yield tokenMsAge(accessToken)?.ms;
      if (!expirationDate || expirationDate <= 0) {
        yield put(action.request());
      } else {
        yield put(action.checkAccessTokenTimeout(expirationDate));
      }
    }
  } else yield put(actionUser.logout());
}

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.ACCESS_TOKEN_REQUEST, getSaga),
    takeLatest(actionTypes.ACCESS_TOKEN_CHECK, authCheckSaga),
    // takeLatest(actionTypes.ACCESS_TOKEN_RECEIVE, authCheckSaga),
    // takeLatest(actionTypesGlobal.CHECK_TOKENS, authCheckSaga),
    takeLatest(actionTypes.ACCESS_TOKEN_CHECK_TIMEOUT, checkAuthAccessTokenTimeoutSaga),
  ]);
}
