import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useMemo, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import CheckBox from "components/CheckBox";
import FieldText from "components/FieldText";
import GalleryComponent from "components/gallery/GalleryComponent";
import GalleryModal from "components/gallery/GalleryModal";
import RecipeCategorySelector from "containers/RecipeCategory/RecipeCategorySelector";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall, onSuccessMutation } from "utils/apiHelper";
import { createAddButton } from "utils/createAddButton";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import IngredientModal from "../IngredientsModal";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import Steps from "./Steps";
import Tags from "./Tags";

const Add = () => {
  let { id } = useParams();
  const URL_ID = id ? id : "";
  const [show, setShow] = useState(false);
  const [ingredients, setIngredients] = useState([]);

  const { t } = useTranslation();

  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const navigation = useNavigate();
  const updateOrCreate = URL_ID
    ? (params) => apiCall("put", api[API_NAME].base + "/" + URL_ID, params)
    : (params) => apiCall("post", api[API_NAME].base, params);
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
    defaultValues: {
      [fieldNames.isPremium]: true,
    },
  });

  // ----------------------------------------------------------------------------- Create or update function (base on URL_ID)
  const { isLoading, data, mutate } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: () => onSuccessMutation("Success", true, navigation, newRoute.base),
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data?.data };
    handleSelectThumbnail(params?.image);
    setIngredients(params?.ingredients);
    const tagsArray = params?.tags?.map((tag) => ({ value: tag }));
    const tagsAr = params?.tagsTranslations?.ar?.map((eq) => ({ value: eq }));
    const tagsNl = params?.tagsTranslations?.nl?.map((eq) => ({ value: eq }));
    const tagsFr = params?.tagsTranslations?.fr?.map((eq) => ({ value: eq }));
    const tagsDe = params?.tagsTranslations?.de?.map((eq) => ({ value: eq }));
    const tagsHi = params?.tagsTranslations?.hi?.map((eq) => ({ value: eq }));
    const tagsId = params?.tagsTranslations?.id?.map((eq) => ({ value: eq }));
    const tagsIt = params?.tagsTranslations?.it?.map((eq) => ({ value: eq }));
    const tagsRu = params?.tagsTranslations?.ru?.map((eq) => ({ value: eq }));
    const tagsEs = params?.tagsTranslations?.es?.map((eq) => ({ value: eq }));
    const tagsTr = params?.tagsTranslations?.tr?.map((eq) => ({ value: eq }));
    const categoryObj = { value: params?.category?._id, label: params?.category?.name };
    reset({
      ...params,
      tags: tagsArray,
      mainName: params?.name,
      categoryId: categoryObj,
      tagsAr: tagsAr,
      tagsNl: tagsNl,
      tagsFr: tagsFr,
      tagsDe: tagsDe,
      tagsHi: tagsHi,
      tagsId: tagsId,
      tagsIt: tagsIt,
      tagsRu: tagsRu,
      tagsEs: tagsEs,
      tagsTr: tagsTr,

      nameAr: params.nameTranslations.ar,
      nameNl: params.nameTranslations.nl,
      nameFr: params.nameTranslations.fr,
      nameDe: params.nameTranslations.de,
      nameHi: params.nameTranslations.hi,
      nameId: params.nameTranslations.id,
      nameIt: params.nameTranslations.it,
      nameRu: params.nameTranslations.ru,
      nameEs: params.nameTranslations.es,
      nameTr: params.nameTranslations.tr,

      descAr: params.descriptionTranslations.ar,
      descNl: params.descriptionTranslations.nl,
      descFr: params.descriptionTranslations.fr,
      descDe: params.descriptionTranslations.de,
      descHi: params.descriptionTranslations.hi,
      descId: params.descriptionTranslations.id,
      descIt: params.descriptionTranslations.it,
      descRu: params.descriptionTranslations.ru,
      descEs: params.descriptionTranslations.es,
      descTr: params.descriptionTranslations.tr,

      stepsAr: params.stepsTranslations.ar,
      stepsNl: params.stepsTranslations.nl,
      stepsFr: params.stepsTranslations.fr,
      stepsDe: params.stepsTranslations.de,
      stepsHi: params.stepsTranslations.hi,
      stepsId: params.stepsTranslations.id,
      stepsIt: params.stepsTranslations.it,
      stepsRu: params.stepsTranslations.ru,
      stepsEs: params.stepsTranslations.es,
      stepsTr: params.stepsTranslations.tr,
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: () => apiCall("get", api[API_NAME].get + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const { ...values } = getValues();
    const requestData = {
      // ...values,
      description: values?.description,
      defaultServingSize: values?.defaultServingSize,
      isPremium: values?.isPremium,
      image: values.image?.path || values.image,
      name: values?.mainName,
      categoryId: values?.categoryId?.value,
      tags: values?.tags.map((tag) => tag.value),
      steps: values?.steps,
      ingredients,
      nameTranslations: {
        ar: values.nameAr,
        nl: values.nameNl,
        fr: values.nameFr,
        de: values.nameDe,
        hi: values.nameHi,
        id: values.nameId,
        it: values.nameIt,
        ru: values.nameRu,
        es: values.nameEs,
        tr: values.nameTr,
      },
      descriptionTranslations: {
        ar: values.descAr,
        nl: values.descNl,
        fr: values.descFr,
        de: values.descDe,
        hi: values.descHi,
        id: values.descId,
        it: values.descIt,
        ru: values.descRu,
        es: values.descEs,
        tr: values.descTr,
      },
      stepsTranslations: {
        ar: values.stepsAr,
        nl: values.stepsNl,
        fr: values.stepsFr,
        de: values.stepsDe,
        hi: values.stepsHi,
        id: values.stepsId,
        it: values.stepsIt,
        ru: values.stepsRu,
        es: values.stepsEs,
        tr: values.stepsTr,
      },
      tagsTranslations: {
        ar: values?.tagsAr.map((eq) => eq.value),
        nl: values?.tagsNl.map((eq) => eq.value),
        fr: values?.tagsFr.map((eq) => eq.value),
        de: values?.tagsDe.map((eq) => eq.value),
        hi: values?.tagsHi.map((eq) => eq.value),
        id: values?.tagsId.map((eq) => eq.value),
        it: values?.tagsIt.map((eq) => eq.value),
        ru: values?.tagsRu.map((eq) => eq.value),
        es: values?.tagsEs.map((eq) => eq.value),
        tr: values?.tagsTr.map((eq) => eq.value),
      },
    };
    requestData.ingredients.forEach((ingredient) => {
      delete ingredient.createdAt;
      delete ingredient.updatedAt;
      delete ingredient._id;
    });
    delete requestData?.mainName;
    delete requestData?.newTag;
    delete requestData?.ingredient;
    delete requestData?.unit;
    console.log("🚀 ~ onSubmit ~ requestData:", requestData);
    mutate(requestData);
  };

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.image, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemoveIngredient = (id) => {
    const filteredData = ingredients?.filter((ingredient) => ingredient?.ingredientId !== id);
    setIngredients(filteredData);
  };

  console.log({ errors });

  return (
    <div>
      <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <PageTitle
        title={URL_ID ? `${t("edit")}  ${t(COMPONENT_NAMES[0])}` : `${t("add")}  ${t(COMPONENT_NAMES[0])}`}
        button={createAddButton({
          title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
          link: newRoute.base + newRoute.list,
        })}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.mainName,
                          register,
                          placeholder: "name",
                          label: "name",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.defaultServingSize,
                          register,
                          placeholder: "1",
                          label: fieldNames.defaultServingSize,
                          errors,
                          type: "number",
                        }}
                      />
                      <FieldText
                        require
                        isTextArea
                        {...{
                          name: fieldNames.description,
                          register,
                          placeholder: fieldNames.description,
                          label: fieldNames.description,
                          errors,
                        }}
                      />
                      <CheckBox
                        {...{
                          name: fieldNames.isPremium,
                          register,
                          label: "isPremium",
                          errors,
                          control,
                        }}
                      />
                      <RecipeCategorySelector
                        isRequired
                        {...{
                          name: fieldNames.categoryId,
                          placeholder: "category",
                          label: "category",
                          errors,
                          control,
                          register,
                          trigger,
                        }}
                      />
                      <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          label: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Steps {...{ name: fieldNames.steps, errors, control, register }} />
            <Tags {...{ name: fieldNames.tags, errors, control, reset, setValue, watch, newValue: "newTag" }} />
            <div className="col-12 border p-4 mt-4">
              <Row className="b-1">
                <Col lg={6}>
                  <h2>{t("ingredients")}</h2>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button onClick={handleShow} className="d-flex align-items-center" size="sm">
                    <div className="gallery-add-title">Add Ingredients</div>
                    <div className="ml-1">+</div>
                  </Button>
                </Col>
                <Col lg={12} className="mt-4">
                  {ingredients?.length
                    ? ingredients?.map((ingredient) => (
                        <div
                          className="d-flex align-items-center mt-3"
                          role="button"
                          key={ingredient?.ingredientId}
                          onClick={() => handleRemoveIngredient(ingredient?.ingredientId)}
                          style={{ maxWidth: "fit-content" }}
                        >
                          <span>{ingredient?.name}</span>
                          <div className="ml-5 close">&times;</div>
                        </div>
                      ))
                    : ""}
                </Col>
              </Row>
            </div>
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("translations")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t("nameTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameAr,
                                  register,
                                  placeholder: "Ar",
                                  label: "Ar",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameNl,
                                  register,
                                  placeholder: "Nl",
                                  label: "Nl",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameFr,
                                  register,
                                  placeholder: "Fr",
                                  label: "Fr",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameDe,
                                  register,
                                  placeholder: "De",
                                  label: "De",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameHi,
                                  register,
                                  placeholder: "Hi",
                                  label: "Hi",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameId,
                                  register,
                                  placeholder: "Id",
                                  label: "Id",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameIt,
                                  register,
                                  placeholder: "It",
                                  label: "It",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameRu,
                                  register,
                                  placeholder: "Ru",
                                  label: "Ru",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameEs,
                                  register,
                                  placeholder: "Es",
                                  label: "Es",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameTr,
                                  register,
                                  placeholder: "Tr",
                                  label: "Tr",
                                  errors,
                                }}
                              />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>{t("descriptionTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descAr,
                                  register,
                                  placeholder: "Ar",
                                  label: "Ar",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descNl,
                                  register,
                                  placeholder: "Nl",
                                  label: "Nl",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descFr,
                                  register,
                                  placeholder: "Fr",
                                  label: "Fr",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descDe,
                                  register,
                                  placeholder: "De",
                                  label: "De",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descHi,
                                  register,
                                  placeholder: "Hi",
                                  label: "Hi",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descId,
                                  register,
                                  placeholder: "Id",
                                  label: "Id",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descIt,
                                  register,
                                  placeholder: "It",
                                  label: "It",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descRu,
                                  register,
                                  placeholder: "Ru",
                                  label: "Ru",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descEs,
                                  register,
                                  placeholder: "Es",
                                  label: "Es",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descTr,
                                  register,
                                  placeholder: "Tr",
                                  label: "Tr",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>{t("stepsTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <Steps {...{ name: fieldNames.stepsAr, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsNl, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsFr, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsDe, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsHi, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsId, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsIt, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsRu, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsEs, errors, control, register }} />
                              <Steps {...{ name: fieldNames.stepsTr, errors, control, register }} />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>{t("tagsTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <Tags {...{ name: fieldNames.tagsAr, errors, control, reset, setValue, watch, newValue: "newTagAr" }} />
                              <Tags {...{ name: fieldNames.tagsNl, errors, control, reset, setValue, watch, newValue: "newTagNl" }} />
                              <Tags {...{ name: fieldNames.tagsFr, errors, control, reset, setValue, watch, newValue: "newTagFr" }} />
                              <Tags {...{ name: fieldNames.tagsDe, errors, control, reset, setValue, watch, newValue: "newTagDe" }} />
                              <Tags {...{ name: fieldNames.tagsHi, errors, control, reset, setValue, watch, newValue: "newTagHi" }} />
                              <Tags {...{ name: fieldNames.tagsId, errors, control, reset, setValue, watch, newValue: "newTagId" }} />
                              <Tags {...{ name: fieldNames.tagsIt, errors, control, reset, setValue, watch, newValue: "newTagIt" }} />
                              <Tags {...{ name: fieldNames.tagsRu, errors, control, reset, setValue, watch, newValue: "newTagRu" }} />
                              <Tags {...{ name: fieldNames.tagsEs, errors, control, reset, setValue, watch, newValue: "newTagEs" }} />
                              <Tags {...{ name: fieldNames.tagsTr, errors, control, reset, setValue, watch, newValue: "newTagTr" }} />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="row sticky-position">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
      <IngredientModal {...{ handleClose, show, control, ingredients, setIngredients }} />
    </div>
  );
};

export default Add;
