import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Breadcrumb from 'react-bootstrap/Breadcrumb';

const PageTitle = ({ title, button, buttons }) => {
  return (
    <div className="row page-titles mx-0">
      <div className="col-sm-6 p-md-0">
        <div className="pt-2 d-flex pl-3">
          <div className="table-title-wrapper ">
            <h3 className="card-title">{title}</h3>
          </div>
        </div>
      </div>

      <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        {buttons ? (
          buttons?.map((x) => {
            return (
              // x?.link ? (
              <Link to={x.link || "#"} key={x.title} target={x?.target}>
                <Button
                  // block
                  type={x?.type || "info"}
                  // htmlType="submit"
                  className={`btn btn-sm mr-2`}
                  form={"form-container"}
                  onClick={x?.onClick || null}
                >
                  {x.title}
                </Button>
              </Link>
            );
          })
        ) : button?.link ? (
          <Link to={button.link}>
            <Button
              // block
              type="info"
              // htmlType="submit"
              className={`btn btn-info btn-sm mr-2`}
              form={"form-container"}
            >
              {button.title}
            </Button>
          </Link>
        ) : (
          button && (
            <Button className={`btn btn-info btn-sm mr-2`} type="info" form={"form-container"} onClick={button?.onClick || null}>
              {button.title}
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default PageTitle;
