import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
  });
