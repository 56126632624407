import { all, delay, put, takeLatest } from "@redux-saga/core/effects";
import actionUser from "redux/store/admin/action";
import { getRefreshToken, tokenMsAge } from "utils/cookies";
import actionAccessToken from "../store/accessToken/action";
import action from "../store/refreshToken/action";
import actionTypes from "../store/refreshToken/actionTypes";

// let timeout;
// const delay = ms => new Promise(resolve => {
//   resolve(setTimeout(() => {
//   }, ms))
// })

export function* checkAuthRefreshTokenTimeoutSaga({ expirationTime, ctx }) {
  // yield call(delay, expirationTime)
  yield delay(String(expirationTime));
  yield put(actionUser.logout(ctx));
}

export function* authCheckSaga({ ctx }) {
  // clearTimeout(timeout)
  const refreshToken = yield getRefreshToken(ctx);
  if (!refreshToken) {
    yield put(actionUser.logout(ctx));
  } else {
    const expirationDate = yield tokenMsAge(refreshToken)?.ms;
    let valid = expirationDate || expirationDate < 0;
    if (!valid) {
      yield put(actionUser.logout(ctx));
    } else {
      yield put(action.checkRefreshTokenTimeout(expirationDate));
      yield put(actionAccessToken.accessTokenCheck(ctx));
    }
  }
}

function* rootSaga() {
  yield all([
    takeLatest(actionTypes.REFRESH_TOKEN_CHECK, authCheckSaga),
    takeLatest(actionTypes.REFRESH_TOKEN_RECEIVE, authCheckSaga),
    takeLatest(actionTypes.REFRESH_TOKEN_CHECK_TIMEOUT, checkAuthRefreshTokenTimeoutSaga),
  ]);
}

export default rootSaga;
