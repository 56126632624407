import { LANGUAGE_DATA, LANGUAGE_DATA_OBJECT } from "enumerations";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import action from "redux/store/admin/action";
import Breadcrumbs from "./BreadCrumbs";
import DropDownBootstrap from "./DropDownBootstrap";

const Navbar = () => {
  const currentAdmin = useSelector((state) => state.admin.data);
  const breadcrumbsRedux = useSelector((state) => state.breadcrumbs);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  const handleLogOut = () => {
    dispatch(action.logout());
    navigation("/login");
  };

  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang?.value);
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row border-bottom">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <button
          className="navbar-toggler navbar-toggler align-self-center ml-auto"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        {/* <div className="search-field d-none d-md-block">
          <div className="d-flex align-items-center h-100">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {breadcrumbsRedux?.map((breadcrumb, index) =>
                  breadcrumb?.link ? (
                    <li className="breadcrumb-item text-capitalize">
                      <Link to={breadcrumb.link}>{t(breadcrumb?.title)}</Link>
                    </li>
                  ) : (
                    <li className="breadcrumb-item active text-capitalize" aria-current="page">
                      {t(breadcrumb?.title)}
                    </li>
                  )
                )}
              </ol>
            </nav>
          </div>
        </div> */}
        <Breadcrumbs />
        <ul className="navbar-nav navbar-nav-right">
          <li>
            <DropDownBootstrap
              data={LANGUAGE_DATA}
              click={changeLanguageHandler}
              value={i18n.language}
              title={LANGUAGE_DATA_OBJECT[i18n.language]?.label}
            />
          </li>

          <li className="nav-item nav-profile">
            <Dropdown alignLeft>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-text">{t("account")}</div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item onClick={handleLogOut}>
                  <i className="mdi mdi-logout mr-2 text-primary"></i>
                  {t("sign_out")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
