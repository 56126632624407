import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  calories: "calories",
  fat: "fat",
  carbohydrates: "carbohydrates",
  proteins: "proteins",
  servingSizes: "servingSizes",
  size: "size",
  unit: "unit",
  grams: "grams",

  nameAr: "nameAr",
  nameNl: "nameNl",
  nameFr: "nameFr",
  nameDe: "nameDe",
  nameHi: "nameHi",
  nameId: "nameId",
  nameIt: "nameIt",
  nameRu: "nameRu",
  nameEs: "nameEs",
  nameTr: "nameTr",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)),
    [fieldNames.calories]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.calories)),
    [fieldNames.fat]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.fat)),
    [fieldNames.carbohydrates]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.carbohydrates)),
    [fieldNames.proteins]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.proteins)),
    [fieldNames.servingSizes]: yup
      .array()
      .of(
        yup.object().shape({
          [fieldNames.size]: yup.number().required("Size is required"),
          [fieldNames.unit]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.unit)).nullable(),
          [fieldNames.grams]: yup.string().required("Grams is required"),
        })
      )
      .optional(),

    [fieldNames.nameAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameAr)).label(fieldNames.nameAr),
    [fieldNames.nameNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameNl)).label(fieldNames.nameNl),
    [fieldNames.nameFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameFr)).label(fieldNames.nameFr),
    [fieldNames.nameDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameDe)).label(fieldNames.nameDe),
    [fieldNames.nameHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameHi)).label(fieldNames.nameHi),
    [fieldNames.nameId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameId)).label(fieldNames.nameId),
    [fieldNames.nameIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameIt)).label(fieldNames.nameIt),
    [fieldNames.nameRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameRu)).label(fieldNames.nameRu),
    [fieldNames.nameEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameEs)).label(fieldNames.nameEs),
    [fieldNames.nameTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameTr)).label(fieldNames.nameTr),
  });
