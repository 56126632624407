import { convertorArrayOption } from "utils/convertor";
import placeholder from "assets/images/placeholder.png";

export const LOCAL_STORAGE_TEMPLATE = {
  token: "token",
};
// ----------------------------------------------------
export const ERRORS = {
  isRequire: (author) => ({ text: "errors.is-require", params: { author } }),
  isNotValid: (author) => ({ text: "errors.is-not-valid", params: { author } }),
  pleaseEnterYour: (author) => ({
    text: "errors.please-enter-your",
    params: { author },
  }),
  pleaseEnter: (author) => ({
    text: "errors.please-enter",
    params: { author },
  }),
  mustBe: (author, kind) => ({
    text: "errors.must-be",
    params: { author, kind },
  }),
  pleaseSelectYour: (author) => ({
    text: "errors.please-select-your",
    params: { author },
  }),
  min: (author) => ({ text: "errors.min", params: { author } }),
  max: (author) => ({ text: "errors.max", params: { author } }),
};
// --------------------------------------------------------

export const tableDisplayed = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 40, value: 40 },
];

// ------------------------------------------------------
export const STATUSES = [
  { label: "Active", value: true },
  { label: "In Active", value: false },
];

export let STATUSES_OBJECT = {};

convertorArrayOption(STATUSES, STATUSES_OBJECT);
// ------------------------------------------------------

export const SORT_OPTION = [
  { label: "newest", value: "desc" },
  { label: "oldest", value: "asc" },
];

export const SORT_OPTION_OBJECT = {};

convertorArrayOption(SORT_OPTION, SORT_OPTION_OBJECT);

// ----------------------------------------

export const PLACEHOLDER_IMAGE = placeholder;

export const LANGUAGE_DATA = [
  { value: "en", label: "English" },
  { value: "tr", label: "Turkey" },
];
export const LANGUAGE_DATA_OBJECT = {};

convertorArrayOption(LANGUAGE_DATA, LANGUAGE_DATA_OBJECT);

export const PERMISSIONS = {
  // -------------------------------
  ADMIN: "ADMIN",
  // -------------------------------
  USER: "USER",
  // -------------------------------
  ROLE: "ROLE",
  // -------------------------------
  BLOG: "BLOG",
  // -------------------------------
  NEWSLETTER: "NEWSLETTER",
  // -------------------------------
  MEDIA: "MEDIA",
  // -------------------------------
  PERMISSION: "PERMISSION",
  // -------------------------------
  SETTING: "SETTING",
  // -------------------------------
};
