import * as yup from "yup";

export const fieldNames = {
  isPremium: "isPremium",
};

export const validation = (URL_ID) =>
  URL_ID
    ? yup.object().shape({
        [fieldNames.isPremium]: yup.boolean().nullable(),
      })
    : yup.object().shape({
        [fieldNames.isPremium]: yup.boolean().nullable(),
      });
