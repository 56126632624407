import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import CheckBox from "components/CheckBox";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall, onSuccessMutation } from "utils/apiHelper";
import { createAddButton } from "utils/createAddButton";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";

const Add = () => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  let { id } = useParams();
  const URL_ID = id ? id : "";

  const { t } = useTranslation();

  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const navigation = useNavigate();
  const updateOrCreate = URL_ID
    ? (params) => apiCall("patch", api[API_NAME].base + "/is-premium/" + URL_ID, params)
    : (params) => apiCall("post", api[API_NAME].base, params);
  // ----------------------------------------------------------------------------- Form initial
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
    defaultValues: {
      [fieldNames.isPremium]: false,
    },
  });

  // ----------------------------------------------------------------------------- Create or update function (base on URL_ID)
  const { isLoading, mutate } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: () => onSuccessMutation("Success", true, navigation, newRoute.base),
  });

  // ----------------------------------------------------------------------------- Get data by id
  const onSuccessDataById = ({ data }) => {
    let params = { ...data?.data };
    reset({
      ...params,
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: () => apiCall("get", api[API_NAME].get + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();
    const requestData = {
      ...values,
      isPremium: values?.isPremium || false,
    };
    console.log("🚀 ~ onSubmit ~ requestData:", requestData);
    mutate(requestData);
  };

  return (
    <div>
      <PageTitle
        title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
        button={createAddButton({
          title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
          link: newRoute.base + newRoute.list,
        })}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <div className="row gap-2">
                        <div className="col bg-light p-1">
                          <h4>{t("name")}:</h4>
                          <p>{dataById?.data?.data?.name}</p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("email")}:</h4>
                          <p>{dataById?.data?.data?.email}</p>
                        </div>
                      </div>
                      <div className="row gap-2 mt-2">
                        <div className="col bg-light p-1">
                          <h4>{t("weight")}:</h4>
                          <p>
                            {dataById?.data?.data?.lifestyle?.weight}
                            {dataById?.data?.data?.lifestyle?.weightUnit}
                          </p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("height")}:</h4>
                          <p>
                            {dataById?.data?.data?.lifestyle?.height}
                            {dataById?.data?.data?.lifestyle?.heightUnit}
                          </p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("activityLevel")}:</h4>
                          <p>{dataById?.data?.data?.lifestyle?.activityLevel}</p>
                        </div>
                      </div>
                      <div className="row gap-2 mt-2">
                        <div className="col bg-light p-1">
                          <h4>{t("age")}:</h4>
                          <p>{dataById?.data?.data?.lifestyle?.age}</p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("gender")}:</h4>
                          <p>{dataById?.data?.data?.lifestyle?.gender}</p>
                        </div>
                      </div>
                      <div className="row">
                        <CheckBox
                          {...{
                            name: fieldNames.isPremium,
                            register,
                            label: "isPremium",
                            errors,
                            control,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("caloriesStuff")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <div className="row gap-2">
                        <div className="col bg-light p-1">
                          <h4>{t("bmi")}:</h4>
                          <p>{dataById?.data?.data?.bmi}</p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("caloriesToBurn")}:</h4>
                          <p>{dataById?.data?.data?.caloriesToBurn}</p>
                        </div>
                      </div>
                      <div className="row gap-2 mt-2">
                        <div className="col bg-light p-1">
                          <h4>{t("dailyCalories")}:</h4>
                          <p>{dataById?.data?.data?.dailyCalories}</p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("dailyCarbohydrates")}:</h4>
                          <p>{dataById?.data?.data?.dailyCarbohydrates}</p>
                        </div>
                      </div>
                      <div className="row gap-2 mt-2">
                        <div className="col bg-light p-1">
                          <h4>{t("dailyFat")}:</h4>
                          <p>{dataById?.data?.data?.dailyFat}</p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("dailyProtein")}:</h4>
                          <p>{dataById?.data?.data?.dailyProtein}</p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("dailyWater")}:</h4>
                          <p>{dataById?.data?.data?.dailyWater}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("goals")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <div className="row">
                        <div className="col-12 m-0 p-0">
                          <h4>{t("overviewGoals")}</h4>
                          <div className="d-flex gap-4">
                            {dataById?.data?.data?.lifestyle?.goals?.map((goal) => (
                              <p>{goal}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row gap-2 mt-2">
                        <div className="col bg-light p-1">
                          <h4>{t("goalWeight")}:</h4>
                          <p>
                            {dataById?.data?.data?.lifestyle?.goalWeight}
                            {dataById?.data?.data?.lifestyle?.goalWeightUnit}
                          </p>
                        </div>
                        <div className="col bg-light p-1">
                          <h4>{t("weeklyWeightGoalChange")}:</h4>
                          <p>{dataById?.data?.data?.lifestyle?.weeklyWeightGoalChange}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="row sticky-position">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
