import DescriptionComponent from "components/DescriptionComponent";
import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "image",
    Component: ({ param }) => {
      return (
        <>
          <span className="d-block mb-1">
            <img src={param?.image} className="image-fluid" />
          </span>
        </>
      );
    },
  },
  {
    header: "ingredients",
    Component: ({ param }) => {
      return (
        <>
          {param.ingredients.map((ingredient, index) => (
            <p key={index}>{ingredient.name || "Unknown"}</p>
          ))}
        </>
      );
    },
  },
  {
    header: "tags",
    Component: ({ param }) => {
      return (
        <>
          {param.tags.map((tag, index) => (
            <p key={index}>{tag}</p>
          ))}
        </>
      );
    },
  },
  {
    header: "isPremium",
    Component: ({ param }) => (param.isPremium ? <span class="badge badge-success">True</span> : <span class="badge badge-danger">False</span>),
  },
  {
    header: "name",
    Component: ({ param }) => param.name,
  },
  {
    header: "description",
    Component: ({ param }) => <DescriptionComponent description={param?.description} />,
  },
  {
    header: "steps",
    Component: ({ param }) => {
      return (
        <>
          {param.steps.map((step, index) => (
            <div>
              <DescriptionComponent description={step} key={index} />
              <br />
            </div>
          ))}
        </>
      );
    },
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
