import FieldText from "components/FieldText";
import { Children } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Content = ({ name, control, errors, register }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const addContent = () => append("");

  const renderContent = () =>
    Children.toArray(
      fields.map((item, index) => (
        <Row className="translation-row" key={item.id}>
          {index >= 0 && (
            <>
              <Col lg={10}>
                <h5>{`${t(name)} ${index + 1}`}</h5>
              </Col>
              <Col lg={2}>
                <Button className="d-flex align-items-center btn-danger" size="sm" onClick={() => remove(index)}>
                  {t("remove")}
                </Button>
              </Col>
              <Col lg={12}>
                <hr style={{ marginBottom: 0 }} />
              </Col>
            </>
          )}
          <FieldText
            register={register}
            require
            name={`${name}[${index}]`}
            placeholder={name}
            error={errors.content?.[index]}
            control={control}
            isTextArea
          />
        </Row>
      ))
    );

  return (
    <div className="col-12 border p-4 mt-4">
      <Row className="b-1">
        <Col lg={6}>
          <h2>{t(name)}</h2>
        </Col>
        <Col lg={6} className="d-flex justify-content-end">
          <Button onClick={addContent} className="d-flex align-items-center" size="sm">
            <div className="gallery-add-title">{t("add")}</div>
            <div className="ml-1">+</div>
          </Button>
        </Col>
      </Row>
      <div className="mt-5 col-12">
        <Controller name={name} control={control} render={renderContent} />
      </div>
    </div>
  );
};

export default Content;
