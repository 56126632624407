import { Badge, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import styles from "./DateInput.module.css";

const FieldDate = ({ control, name, label, valid, register, errors, require, type, error, placeholder, setState, onChange, disables, ...other }) => {
  let newRegister = register ? register(name) : {};

  const [t] = useTranslation();
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  return (
    <Form.Group className="form-group ">
      <div className={"d-flex justify-content-between"}>
        {label ? (
          <label htmlFor={name}>
            {!disables?.translate?.label ? compareTranslate(t, label) : label}
            {require ? " *" : ""}
          </label>
        ) : (
          ""
        )}
      </div>

      <div className="input-wrapper">
        {control ? (
          <Controller
            control={control}
            ref={newRegister}
            name={name}
            render={({ field: { onChange: change, value } }) => {
              function handleChange(params) {
                onChange?.(params);
                change(params);
              }
              return (
                <div>
                  <ReactDatePicker
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    showTimeInput
                    dateFormat="Pp"
                    showYearDropdown
                    className="form-control"
                    id={name}
                    name={name}
                    placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
                    onChange={handleChange}
                    selected={value}
                    {...other}
                  >
                    <Badge className={styles.now} onClick={() => handleChange(new Date())}>
                      Now!
                    </Badge>
                  </ReactDatePicker>
                </div>
              );
            }}
          />
        ) : (
          <div>
            <ReactDatePicker
              // showTimeSelect
              timeFormat="p"
              timeIntervals={15}
              showTimeInput
              dateFormat="Pp"
              showYearDropdown
              className="form-control"
              id={name}
              name={name}
              placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
              onChange={onChange}
              {...other}
              {...newRegister}
            >
              <Badge className={styles.now} onClick={() => onChange(new Date())}>
                Now!
              </Badge>
            </ReactDatePicker>
          </div>
        )}
      </div>
      {errors?.[name] ? (
        <div className={`error-text mt-1  `}>
          {!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}
        </div>
      ) : (
        ""
      )}
      {error ? <div className={`error-text mt-1  `}>{!disables?.translate?.error ? compareTranslate(t, error?.message) : error?.message}</div> : ""}
    </Form.Group>
  );
};
export default FieldDate;
