import React, { useMemo } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";

import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";

const FieldSelect = ({
  name,
  control,
  label,
  valid,
  register,
  errors,
  require,
  options,
  setState,
  disables,
  withAll,
  placeholder,
  disabled,
  ...other
}) => {
  let newRegister = register ? register(name) : {};
  const [t] = useTranslation();

  let newOptions = useMemo(() => {
    const params = options.map((x) => ({ ...x, ...(disables?.translate?.options && { label: compareTranslate(t, x?.label) }) }));

    if (withAll)
      params?.unshift?.({
        label: t("all"),
        value: "all",
      });
    return params;
  }, [options]);

  return (
    <Form.Group className="form-group ">
      {label ? (
        <div className={"d-flex justify-content-between"}>
          <label htmlFor={name}>
            {!disables?.translate?.label ? compareTranslate(t, label) : label}
            {require ? " *" : ""}
          </label>
          {errors ? (
            <div className={`error-text `}>
              {!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {control ? (
        <Controller
          control={control}
          ref={newRegister}
          name={name}
          render={({ field: { onChange, value } }) => {
            function handleChange(params) {
              onChange?.(params);
              setState?.(params);
            }
            return (
              <Select
                className="custom-selector"
                placeholder={t(placeholder || "selectSearch")}
                options={newOptions}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                {...other}
              />
            );
          }}
        />
      ) : (
        <Select className="custom-selector" options={newOptions} placeholder={t(placeholder || "selectSearch")} disabled={disabled} {...other} />
      )}
    </Form.Group>
  );
};
export default FieldSelect;
