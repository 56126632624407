import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  description: "description",
  listOrderNumber: "listOrderNumber",
  durationInDays: "durationInDays",
  actualIdIos: "actualIdIos",
  actualIdAndroid: "actualIdAndroid",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
    [fieldNames.actualIdIos]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.actualIdIos)).label(fieldNames.actualIdIos),
    [fieldNames.actualIdAndroid]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.actualIdAndroid)).label(fieldNames.actualIdAndroid),
    [fieldNames.listOrderNumber]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber)).label(fieldNames.listOrderNumber),
    [fieldNames.durationInDays]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.durationInDays)).label(fieldNames.durationInDays),
  });
