import environment from "configs/baseUrl.json";

export default {
  host: environment.BASE_URL,
  refreshToken: "/auth/refresh",
  verify: "/auth/verify-login",
  network: "/network",
  dashboard: "/admin/dashboard",
  // ------------------------------------------------- media
  folder: {
    base: "/media/folder",
    list: "/media/folder/list",
    get: "/media/folder/get",
    delete: "/media/folder/get",
    getBySlug: "/media/folder/get/slug",
    changeStatus: "/media/folder/is-active",
  },
  gallery: {
    base: "/admin/gallery",
    list: "/admin/gallery",
    get: "/admin/gallery",
    delete: "/admin/gallery",
    changeStatus: "/admin/gallery",
  },

  // --------------------- TODO: remove everything above this line
  auth: {
    login: "/auth/login",
  },
  admin: {
    base: "/admin",
    list: "/admin",
    get: "/admin",
    delete: "/admin",
  },
  cardio: {
    base: "/admin/cardio",
    list: "/admin/cardio",
    get: "/admin/cardio",
    delete: "/admin/cardio",
  },
  strength: {
    base: "/admin/strength",
    list: "/admin/strength",
    get: "/admin/strength",
    delete: "/admin/strength",
  },
  recipeCategory: {
    base: "/admin/recipe-category",
    list: "/admin/recipe-category",
    get: "/admin/recipe-category",
    delete: "/admin/recipe-category",
  },
  servingUnit: {
    base: "/admin/serving-unit",
    list: "/admin/serving-unit",
    get: "/admin/serving-unit",
    delete: "/admin/serving-unit",
  },
  guideCategory: {
    base: "/admin/guide-category",
    list: "/admin/guide-category",
    get: "/admin/guide-category",
    delete: "/admin/guide-category",
  },
  aiQa: {
    base: "/admin/ai-qa",
    list: "/admin/ai-qa",
    get: "/admin/ai-qa",
    delete: "/admin/ai-qa",
  },
  ai: {
    base: "/admin/setting",
    list: "/admin/setting",
    get: "/admin/setting",
    delete: "/admin/setting",
  },
  ingredients: {
    base: "/admin/ingredients",
    list: "/admin/ingredients",
    get: "/admin/ingredients",
    delete: "/admin/ingredients",
  },
  recipes: {
    base: "/admin/recipes",
    list: "/admin/recipes",
    get: "/admin/recipes",
    delete: "/admin/recipes",
  },
  guides: {
    base: "/admin/guides",
    list: "/admin/guides",
    get: "/admin/guides",
    delete: "/admin/guides",
  },
  premiumPlan: {
    base: "/admin/premium-plan",
    list: "/admin/premium-plan",
    get: "/admin/premium-plan",
    delete: "/admin/premium-plan",
  },
  users: {
    base: "/admin/users",
    list: "/admin/users",
    get: "/admin/users",
    delete: "/admin/users",
  },
  subscriptions: {
    base: "/admin/subscriptions",
    list: "/admin/subscriptions",
    get: "/admin/subscriptions",
    delete: "/admin/subscriptions",
  },
  files: {
    base: "/admin/files",
    list: "/admin/files",
    get: "/admin/files",
    delete: "/admin/files",
    changeStatus: "/admin/files",
  },
};
