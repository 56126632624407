export default function getDataInObjectDepth(path, obj) {
  if (!path) return obj;

  const props = path.split(".");
  let currentObj = obj;

  for (let i = 0; i < props.length - 1; i++) {
    const prop = props[i];
    const candidate = currentObj[prop];

    if (candidate !== undefined) {
      currentObj = candidate;
    } else {
      return undefined;
    }
  }

  return currentObj[props[props.length - 1]];
}
