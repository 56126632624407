import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  description: "description",
  descAr: "descAr",
  descNl: "descNl",
  descFr: "descFr",
  descDe: "descDe",
  descHi: "descHi",
  descId: "descId",
  descIt: "descIt",
  descRu: "descRu",
  descEs: "descEs",
  descTr: "descTr",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),

    [fieldNames.descAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descAr)).label(fieldNames.descAr),
    [fieldNames.descNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descNl)).label(fieldNames.descNl),
    [fieldNames.descFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descFr)).label(fieldNames.descFr),
    [fieldNames.descDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descDe)).label(fieldNames.descDe),
    [fieldNames.descHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descHi)).label(fieldNames.descHi),
    [fieldNames.descId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descId)).label(fieldNames.descId),
    [fieldNames.descIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descIt)).label(fieldNames.descIt),
    [fieldNames.descRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descRu)).label(fieldNames.descRu),
    [fieldNames.descEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descEs)).label(fieldNames.descEs),
    [fieldNames.descTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descTr)).label(fieldNames.descTr),
  });
