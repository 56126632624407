import { useLocation } from "react-router-dom";

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const useQueryString = ({ limit = 10, ...other } = "") => {
  // const location = window.location;
  let queryStrings = { page: 1, limit, ...other };
  const location = useLocation();
  function decode(s) {
    let pl = /\+/g;
    return decodeURIComponent(s.replace(pl, " "));
  }
  (window.onpopstate = function () {
    let search = /([^&=]+)=?([^&]*)/g,
      match,
      query = location.search.substring(1);
    queryStrings = { ...queryStrings };
    while ((match = search.exec(query))) {
      // exec: search.exec(query),
      queryStrings[decode(match[1])] = decode(match[2]);
    }
  })();
  return { ...queryStrings };
};

export default useQueryString;
