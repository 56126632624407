import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useMemo, useState } from "react";
import { Accordion, Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldText from "components/FieldText";
import GalleryComponent from "components/gallery/GalleryComponent";
import GalleryModal from "components/gallery/GalleryModal";
import GuideCategorySelector from "containers/GuideCategory/GuideCategorySelector";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall, onSuccessMutation } from "utils/apiHelper";
import { createAddButton } from "utils/createAddButton";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import Content from "./Content";
import Equipments from "./Equipments";
import FieldSelect from "components/FieldSelect";
import CheckBox from "components/CheckBox";

const Add = () => {
  let { id } = useParams();
  const URL_ID = id ? id : "";
  const { t } = useTranslation();
  const guideTypeOptions = [
    { value: "calisthenics", label: "Calisthenics" },
    { value: "strength", label: "Strength" },
    { value: "cardio", label: "Cardio" },
  ];

  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const navigation = useNavigate();
  const updateOrCreate = URL_ID
    ? (params) => apiCall("put", api[API_NAME].base + "/" + URL_ID, params)
    : (params) => apiCall("post", api[API_NAME].base, params);
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
    defaultValues: {
      [fieldNames.isPremium]: true,
    },
  });

  // ----------------------------------------------------------------------------- Create or update function (base on URL_ID)
  const { isLoading, data, mutate } = useMutationCustom({
    url: updateOrCreate,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: () => onSuccessMutation("Success", true, navigation, newRoute.base),
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data?.data };
    handleSelectThumbnail(params?.image);
    handleSelectThumbnailVideo(params?.video);
    const equipmentsArray = params?.equipments?.map((eq) => ({ value: eq }));
    const eqAr = params?.equipmentsTranslations?.ar?.map((eq) => ({ value: eq }));
    const eqNl = params?.equipmentsTranslations?.nl?.map((eq) => ({ value: eq }));
    const eqFr = params?.equipmentsTranslations?.fr?.map((eq) => ({ value: eq }));
    const eqDe = params?.equipmentsTranslations?.de?.map((eq) => ({ value: eq }));
    const eqHi = params?.equipmentsTranslations?.hi?.map((eq) => ({ value: eq }));
    const eqId = params?.equipmentsTranslations?.id?.map((eq) => ({ value: eq }));
    const eqIt = params?.equipmentsTranslations?.it?.map((eq) => ({ value: eq }));
    const eqRu = params?.equipmentsTranslations?.ru?.map((eq) => ({ value: eq }));
    const eqEs = params?.equipmentsTranslations?.es?.map((eq) => ({ value: eq }));
    const eqTr = params?.equipmentsTranslations?.tr?.map((eq) => ({ value: eq }));
    const guidTypeObj = { value: params?.guideType, label: params?.guideType };
    const categoryObj = { value: params?.category?._id, label: params?.category?.name };

    reset({
      ...params,
      equipments: equipmentsArray,
      guideType: guidTypeObj,
      categoryId: categoryObj,
      eqAr: eqAr,
      eqNl: eqNl,
      eqFr: eqFr,
      eqDe: eqDe,
      eqHi: eqHi,
      eqId: eqId,
      eqIt: eqIt,
      eqRu: eqRu,
      eqEs: eqEs,
      eqTr: eqTr,
      nameAr: params.nameTranslations.ar,
      nameNl: params.nameTranslations.nl,
      nameFr: params.nameTranslations.fr,
      nameDe: params.nameTranslations.de,
      nameHi: params.nameTranslations.hi,
      nameId: params.nameTranslations.id,
      nameIt: params.nameTranslations.it,
      nameRu: params.nameTranslations.ru,
      nameEs: params.nameTranslations.es,
      nameTr: params.nameTranslations.tr,

      descAr: params.descriptionTranslations.ar,
      descNl: params.descriptionTranslations.nl,
      descFr: params.descriptionTranslations.fr,
      descDe: params.descriptionTranslations.de,
      descHi: params.descriptionTranslations.hi,
      descId: params.descriptionTranslations.id,
      descIt: params.descriptionTranslations.it,
      descRu: params.descriptionTranslations.ru,
      descEs: params.descriptionTranslations.es,
      descTr: params.descriptionTranslations.tr,

      guideTypeAr: params.guideTypeTranslations.ar,
      guideTypeNl: params.guideTypeTranslations.nl,
      guideTypeFr: params.guideTypeTranslations.fr,
      guideTypeDe: params.guideTypeTranslations.de,
      guideTypeHi: params.guideTypeTranslations.hi,
      guideTypeId: params.guideTypeTranslations.id,
      guideTypeIt: params.guideTypeTranslations.it,
      guideTypeRu: params.guideTypeTranslations.ru,
      guideTypeEs: params.guideTypeTranslations.es,
      guideTypeTr: params.guideTypeTranslations.tr,

      contentAr: params.contentTranslations.ar,
      contentNl: params.contentTranslations.nl,
      contentFr: params.contentTranslations.fr,
      contentDe: params.contentTranslations.de,
      contentHi: params.contentTranslations.hi,
      contentId: params.contentTranslations.id,
      contentIt: params.contentTranslations.it,
      contentRu: params.contentTranslations.ru,
      contentEs: params.contentTranslations.es,
      contentTr: params.contentTranslations.tr,
    });
  };
  const { data: dataById } = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: () => apiCall("get", api[API_NAME].get + "/" + URL_ID),
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const { ...values } = getValues();
    const requestData = {
      // ...values,
      name: values?.name,
      description: values.description,
      isPremium: values.isPremium,
      image: values.image?.path || values.image,
      video: values.video?.path || values.video,
      categoryId: values?.categoryId?.value,
      equipments: values?.equipments.map((eq) => eq.value),
      guideType: values?.guideType?.value,
      content: values.content,
      durationInMinutes: values.durationInMinutes,
      nameTranslations: {
        ar: values.nameAr,
        nl: values.nameNl,
        fr: values.nameFr,
        de: values.nameDe,
        hi: values.nameHi,
        id: values.nameId,
        it: values.nameIt,
        ru: values.nameRu,
        es: values.nameEs,
        tr: values.nameTr,
      },
      descriptionTranslations: {
        ar: values.descAr,
        nl: values.descNl,
        fr: values.descFr,
        de: values.descDe,
        hi: values.descHi,
        id: values.descId,
        it: values.descIt,
        ru: values.descRu,
        es: values.descEs,
        tr: values.descTr,
      },
      guideTypeTranslations: {
        ar: values.guideTypeAr,
        nl: values.guideTypeNl,
        fr: values.guideTypeFr,
        de: values.guideTypeDe,
        hi: values.guideTypeHi,
        id: values.guideTypeId,
        it: values.guideTypeIt,
        ru: values.guideTypeRu,
        es: values.guideTypeEs,
        tr: values.guideTypeTr,
      },
      contentTranslations: {
        ar: values.contentAr,
        nl: values.contentNl,
        fr: values.contentFr,
        de: values.contentDe,
        hi: values.contentHi,
        id: values.contentId,
        it: values.contentIt,
        ru: values.contentRu,
        es: values.contentEs,
        tr: values.contentTr,
      },
      equipmentsTranslations: {
        ar: values?.eqAr.map((eq) => eq.value),
        nl: values?.eqNl.map((eq) => eq.value),
        fr: values?.eqFr.map((eq) => eq.value),
        de: values?.eqDe.map((eq) => eq.value),
        hi: values?.eqHi.map((eq) => eq.value),
        id: values?.eqId.map((eq) => eq.value),
        it: values?.eqIt.map((eq) => eq.value),
        ru: values?.eqRu.map((eq) => eq.value),
        es: values?.eqEs.map((eq) => eq.value),
        tr: values?.eqTr.map((eq) => eq.value),
      },
    };
    // delete requestData.newEq;
    mutate(requestData);
    console.log("🚀 ~ onSubmit ~ requestData:", requestData);
  };

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.image, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };

  //  ----------------------------------------- VIDEO ----------------------------------------- //
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [thumbnailVideo, setThumbnailVideo] = useState(null);
  const handleGalleryModalVideo = () => {
    setShowModalVideo(!showModalVideo);
  };
  const handleSelectThumbnailVideo = (thumbnail) => {
    setValue(fieldNames.video, thumbnail, {
      shouldValidate: true,
    });
    setThumbnailVideo(thumbnail);
  };

  return (
    <div>
      <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <GalleryModal
        {...{
          showModal: showModalVideo,
          handleModal: handleGalleryModalVideo,
          handleClickedImage: handleSelectThumbnailVideo,
          selectedPhoto: thumbnailVideo,
        }}
      />
      <PageTitle
        title={URL_ID ? `${t("edit")}  ${t(COMPONENT_NAMES[0])}` : `${t("add")}  ${t(COMPONENT_NAMES[0])}`}
        button={createAddButton({
          title: `${t("back_to")} ${t(COMPONENT_NAMES[0])} ${t("list")}`,
          link: newRoute.base + newRoute.list,
        })}
      />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("basic_information")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.name,
                          register,
                          placeholder: "name",
                          label: "name",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        isTextArea
                        {...{
                          name: fieldNames.description,
                          register,
                          placeholder: fieldNames.description,
                          label: fieldNames.description,
                          errors,
                        }}
                      />
                      <GuideCategorySelector
                        isRequired
                        {...{
                          name: fieldNames.categoryId,
                          placeholder: "category",
                          label: "category",
                          errors,
                          control,
                          register,
                          trigger,
                        }}
                      />
                      <CheckBox
                        {...{
                          name: fieldNames.isPremium,
                          register,
                          label: "isPremium",
                          errors,
                          control,
                        }}
                      />
                      <FieldSelect
                        {...{
                          name: fieldNames.guideType,
                          placeholder: fieldNames.guideType,
                          label: fieldNames.guideType,
                          errors,
                          control,
                          register,
                          options: guideTypeOptions,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.durationInMinutes,
                          register,
                          placeholder: fieldNames.durationInMinutes,
                          label: fieldNames.durationInMinutes,
                          errors,
                          type: "number",
                        }}
                      />
                      <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          label: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      />
                      <div className="mt-4">
                        <GalleryComponent
                          isRequired
                          {...{
                            handleModal: handleGalleryModalVideo,
                            selectedPhoto: thumbnailVideo,
                            label: "video",
                            name: fieldNames.video,
                            Controller,
                            control,
                            errors,
                            register,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Content {...{ name: fieldNames.content, errors, control, register }} />
            <Equipments {...{ name: fieldNames.equipments, errors, control, reset, setValue, watch, newValueName: "newEq" }} />

            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("translations")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t("nameTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameAr,
                                  register,
                                  placeholder: "Ar",
                                  label: "Ar",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameNl,
                                  register,
                                  placeholder: "Nl",
                                  label: "Nl",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameFr,
                                  register,
                                  placeholder: "Fr",
                                  label: "Fr",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameDe,
                                  register,
                                  placeholder: "De",
                                  label: "De",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameHi,
                                  register,
                                  placeholder: "Hi",
                                  label: "Hi",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameId,
                                  register,
                                  placeholder: "Id",
                                  label: "Id",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameIt,
                                  register,
                                  placeholder: "It",
                                  label: "It",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameRu,
                                  register,
                                  placeholder: "Ru",
                                  label: "Ru",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameEs,
                                  register,
                                  placeholder: "Es",
                                  label: "Es",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.nameTr,
                                  register,
                                  placeholder: "Tr",
                                  label: "Tr",
                                  errors,
                                }}
                              />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>{t("descriptionTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descAr,
                                  register,
                                  placeholder: "Ar",
                                  label: "Ar",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descNl,
                                  register,
                                  placeholder: "Nl",
                                  label: "Nl",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descFr,
                                  register,
                                  placeholder: "Fr",
                                  label: "Fr",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descDe,
                                  register,
                                  placeholder: "De",
                                  label: "De",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descHi,
                                  register,
                                  placeholder: "Hi",
                                  label: "Hi",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descId,
                                  register,
                                  placeholder: "Id",
                                  label: "Id",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descIt,
                                  register,
                                  placeholder: "It",
                                  label: "It",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descRu,
                                  register,
                                  placeholder: "Ru",
                                  label: "Ru",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descEs,
                                  register,
                                  placeholder: "Es",
                                  label: "Es",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.descTr,
                                  register,
                                  placeholder: "Tr",
                                  label: "Tr",
                                  errors,
                                  isTextArea: true,
                                }}
                              />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>{t("guideTypeTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeAr,
                                  register,
                                  placeholder: "Ar",
                                  label: "Ar",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeNl,
                                  register,
                                  placeholder: "Nl",
                                  label: "Nl",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeFr,
                                  register,
                                  placeholder: "Fr",
                                  label: "Fr",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeDe,
                                  register,
                                  placeholder: "De",
                                  label: "De",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeHi,
                                  register,
                                  placeholder: "Hi",
                                  label: "Hi",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeId,
                                  register,
                                  placeholder: "Id",
                                  label: "Id",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeIt,
                                  register,
                                  placeholder: "It",
                                  label: "It",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeRu,
                                  register,
                                  placeholder: "Ru",
                                  label: "Ru",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeEs,
                                  register,
                                  placeholder: "Es",
                                  label: "Es",
                                  errors,
                                }}
                              />
                              <FieldText
                                require
                                {...{
                                  name: fieldNames.guideTypeTr,
                                  register,
                                  placeholder: "Tr",
                                  label: "Tr",
                                  errors,
                                }}
                              />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>{t("contentTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <Content {...{ name: fieldNames.contentAr, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentNl, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentFr, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentDe, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentHi, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentId, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentIt, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentRu, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentEs, errors, control, register }} />
                              <Content {...{ name: fieldNames.contentTr, errors, control, register }} />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>{t("equipmentTranslations")}</Accordion.Header>
                          <Accordion.Body>
                            <>
                              <div className="b-1">
                                <h4 className="mb-4"></h4>
                              </div>
                              <Equipments {...{ name: fieldNames.eqAr, errors, control, reset, setValue, watch, newValueName: "newEqAr" }} />
                              <Equipments {...{ name: fieldNames.eqNl, errors, control, reset, setValue, watch, newValueName: "newEqNl" }} />
                              <Equipments {...{ name: fieldNames.eqFr, errors, control, reset, setValue, watch, newValueName: "newEqFr" }} />
                              <Equipments {...{ name: fieldNames.eqDe, errors, control, reset, setValue, watch, newValueName: "newEqDe" }} />
                              <Equipments {...{ name: fieldNames.eqHi, errors, control, reset, setValue, watch, newValueName: "newEqHi" }} />
                              <Equipments {...{ name: fieldNames.eqId, errors, control, reset, setValue, watch, newValueName: "newEqId" }} />
                              <Equipments {...{ name: fieldNames.eqIt, errors, control, reset, setValue, watch, newValueName: "newEqIt" }} />
                              <Equipments {...{ name: fieldNames.eqRu, errors, control, reset, setValue, watch, newValueName: "newEqRu" }} />
                              <Equipments {...{ name: fieldNames.eqEs, errors, control, reset, setValue, watch, newValueName: "newEqEs" }} />
                              <Equipments {...{ name: fieldNames.eqTr, errors, control, reset, setValue, watch, newValueName: "newEqTr" }} />
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="row sticky-position">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("actions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <Button block type="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;
