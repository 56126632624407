import * as yup from "yup";
import { ERRORS } from "enumerations";

export const fieldNames = {
  name: "name",
  description: "description",
  content: "content",
  categoryId: "categoryId",
  equipments: "equipments",
  image: "image",
  video: "video",
  guideType: "guideType",
  durationInMinutes: "durationInMinutes",
  isPremium: "isPremium",

  nameAr: "nameAr",
  nameNl: "nameNl",
  nameFr: "nameFr",
  nameDe: "nameDe",
  nameHi: "nameHi",
  nameId: "nameId",
  nameIt: "nameIt",
  nameRu: "nameRu",
  nameEs: "nameEs",
  nameTr: "nameTr",

  descAr: "descAr",
  descNl: "descNl",
  descFr: "descFr",
  descDe: "descDe",
  descHi: "descHi",
  descId: "descId",
  descIt: "descIt",
  descRu: "descRu",
  descEs: "descEs",
  descTr: "descTr",

  guideTypeAr: "guideTypeAr",
  guideTypeNl: "guideTypeNl",
  guideTypeFr: "guideTypeFr",
  guideTypeDe: "guideTypeDe",
  guideTypeHi: "guideTypeHi",
  guideTypeId: "guideTypeId",
  guideTypeIt: "guideTypeIt",
  guideTypeRu: "guideTypeRu",
  guideTypeEs: "guideTypeEs",
  guideTypeTr: "guideTypeTr",

  eqAr: "eqAr",
  eqNl: "eqNl",
  eqFr: "eqFr",
  eqDe: "eqDe",
  eqHi: "eqHi",
  eqId: "eqId",
  eqIt: "eqIt",
  eqRu: "eqRu",
  eqEs: "eqEs",
  eqTr: "eqTr",

  contentAr: "contentAr",
  contentNl: "contentNl",
  contentFr: "contentFr",
  contentDe: "contentDe",
  contentHi: "contentHi",
  contentId: "contentId",
  contentIt: "contentIt",
  contentRu: "contentRu",
  contentEs: "contentEs",
  contentTr: "contentTr",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
    [fieldNames.content]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.content)).label(fieldNames.content)),
    [fieldNames.isPremium]: yup.boolean().required(ERRORS.pleaseEnterYour(fieldNames.isPremium)).label(fieldNames.isPremium),
    // [fieldNames.ingredients]: yup.array().of(ingredients),
    [fieldNames.categoryId]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.categoryId)).label(fieldNames.categoryId),
    [fieldNames.equipments]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.equipments)).label(fieldNames.equipments),
      })
    ),

    guideType: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required(),

    [fieldNames.durationInMinutes]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.durationInMinutes)).label(fieldNames.durationInMinutes),
    [fieldNames.image]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed();
      }
    }),
    [fieldNames.video]: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.video)).label(fieldNames.video); // schema for object
        case "string":
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.video)).label(fieldNames.video); // schema for string
        default:
          return yup.mixed();
      }
    }),

    [fieldNames.nameAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameAr)).label(fieldNames.nameAr),
    [fieldNames.nameNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameNl)).label(fieldNames.nameNl),
    [fieldNames.nameFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameFr)).label(fieldNames.nameFr),
    [fieldNames.nameDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameDe)).label(fieldNames.nameDe),
    [fieldNames.nameHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameHi)).label(fieldNames.nameHi),
    [fieldNames.nameId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameId)).label(fieldNames.nameId),
    [fieldNames.nameIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameIt)).label(fieldNames.nameIt),
    [fieldNames.nameRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameRu)).label(fieldNames.nameRu),
    [fieldNames.nameEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameEs)).label(fieldNames.nameEs),
    [fieldNames.nameTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameTr)).label(fieldNames.nameTr),

    [fieldNames.guideTypeAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeAr)).label(fieldNames.guideTypeAr),
    [fieldNames.guideTypeNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeNl)).label(fieldNames.guideTypeNl),
    [fieldNames.guideTypeFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeFr)).label(fieldNames.guideTypeFr),
    [fieldNames.guideTypeDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeDe)).label(fieldNames.guideTypeDe),
    [fieldNames.guideTypeHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeHi)).label(fieldNames.guideTypeHi),
    [fieldNames.guideTypeId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeId)).label(fieldNames.guideTypeId),
    [fieldNames.guideTypeIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeIt)).label(fieldNames.guideTypeIt),
    [fieldNames.guideTypeRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeRu)).label(fieldNames.guideTypeRu),
    [fieldNames.guideTypeEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeEs)).label(fieldNames.guideTypeEs),
    [fieldNames.guideTypeTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.guideTypeTr)).label(fieldNames.guideTypeTr),

    [fieldNames.descAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descAr)).label(fieldNames.descAr),
    [fieldNames.descNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descNl)).label(fieldNames.descNl),
    [fieldNames.descFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descFr)).label(fieldNames.descFr),
    [fieldNames.descDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descDe)).label(fieldNames.descDe),
    [fieldNames.descHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descHi)).label(fieldNames.descHi),
    [fieldNames.descId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descId)).label(fieldNames.descId),
    [fieldNames.descIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descIt)).label(fieldNames.descIt),
    [fieldNames.descRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descRu)).label(fieldNames.descRu),
    [fieldNames.descEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descEs)).label(fieldNames.descEs),
    [fieldNames.descTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descTr)).label(fieldNames.descTr),

    [fieldNames.eqAr]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqAr)).label(fieldNames.eqAr),
      })
    ),
    [fieldNames.eqNl]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqNl)).label(fieldNames.eqNl),
      })
    ),
    [fieldNames.eqFr]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqFr)).label(fieldNames.eqFr),
      })
    ),
    [fieldNames.eqDe]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqDe)).label(fieldNames.eqDe),
      })
    ),
    [fieldNames.eqHi]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqHi)).label(fieldNames.eqHi),
      })
    ),
    [fieldNames.eqId]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqId)).label(fieldNames.eqId),
      })
    ),
    [fieldNames.eqIt]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqIt)).label(fieldNames.eqIt),
      })
    ),
    [fieldNames.eqRu]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqRu)).label(fieldNames.eqRu),
      })
    ),
    [fieldNames.eqEs]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqEs)).label(fieldNames.eqEs),
      })
    ),
    [fieldNames.eqTr]: yup.array().of(
      yup.object().shape({
        value: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.eqTr)).label(fieldNames.eqTr),
      })
    ),

    [fieldNames.contentAr]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentAr)).label(fieldNames.contentAr)),
    [fieldNames.contentNl]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentNl)).label(fieldNames.contentNl)),
    [fieldNames.contentFr]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentFr)).label(fieldNames.contentFr)),
    [fieldNames.contentDe]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentDe)).label(fieldNames.contentDe)),
    [fieldNames.contentHi]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentHi)).label(fieldNames.contentHi)),
    [fieldNames.contentId]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentId)).label(fieldNames.contentId)),
    [fieldNames.contentIt]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentIt)).label(fieldNames.contentIt)),
    [fieldNames.contentRu]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentRu)).label(fieldNames.contentRu)),
    [fieldNames.contentEs]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentEs)).label(fieldNames.contentEs)),
    [fieldNames.contentTr]: yup.array().of(yup.string().required(ERRORS.pleaseEnterYour(fieldNames.contentTr)).label(fieldNames.contentTr)),
  });
