import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  nameAr: "nameAr",
  nameNl: "nameNl",
  nameFr: "nameFr",
  nameDe: "nameDe",
  nameHi: "nameHi",
  nameId: "nameId",
  nameIt: "nameIt",
  nameRu: "nameRu",
  nameEs: "nameEs",
  nameTr: "nameTr",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),

    [fieldNames.nameAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameAr)).label(fieldNames.nameAr),
    [fieldNames.nameNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameNl)).label(fieldNames.nameNl),
    [fieldNames.nameFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameFr)).label(fieldNames.nameFr),
    [fieldNames.nameDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameDe)).label(fieldNames.nameDe),
    [fieldNames.nameHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameHi)).label(fieldNames.nameHi),
    [fieldNames.nameId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameId)).label(fieldNames.nameId),
    [fieldNames.nameIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameIt)).label(fieldNames.nameIt),
    [fieldNames.nameRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameRu)).label(fieldNames.nameRu),
    [fieldNames.nameEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameEs)).label(fieldNames.nameEs),
    [fieldNames.nameTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.nameTr)).label(fieldNames.nameTr),
  });
