import DescriptionComponent from "components/DescriptionComponent";
import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "prompt",
    Component: ({ param }) => <DescriptionComponent description={param.prompt} />,
  },
  {
    header: "maxTokens",
    Component: ({ param }) => `${param.maxTokens || 0}`,
  },

  {
    header: "maxMessagesPerDay",
    Component: ({ param }) => param.maxMessagesPerDay || 0,
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
