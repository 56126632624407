import { useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FolderDetail from "./GalleryTab/FolderDetail";
import UploadTab from "./UploadTab";

const GalleryModal = ({ showModal, handleModal, handleClickedImage, selectedPhoto, isGalleryDisabled, FOLDER_SLUG, editable }) => {
  const [activeTab, setActiveTab] = useState(isGalleryDisabled ? "upload" : "gallery");
  const { t } = useTranslation();

  return (
    <Modal show={showModal} onHide={handleModal} size="lg" keyboard scrollable>
      <Tab.Container id="modal-tab-container" defaultActiveKey={activeTab}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <Row>
              <Col sm={3}>
                <Nav variant="pills">
                  {isGalleryDisabled ? (
                    ""
                  ) : (
                    <Nav.Item>
                      <Nav.Link eventKey="gallery">{t("gallery")}</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="upload">{t("upload")}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Content className="pt-0">
            {isGalleryDisabled ? (
              ""
            ) : (
              <Tab.Pane eventKey="gallery">
                {/* <GalleryTab {...{ handleClickedImage, selectedPhoto, handleModal }} /> */}
                <FolderDetail {...{ handleClickedImage, selectedPhoto, handleModal, editable }} />
              </Tab.Pane>
            )}
            <Tab.Pane eventKey="upload">
              <UploadTab {...{ handleClickedImage, selectedPhoto, handleModal, FOLDER_SLUG }} />
            </Tab.Pane>
          </Tab.Content>
        </Modal.Body>
      </Tab.Container>
    </Modal>
  );
};

export default GalleryModal;
