import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="search-field d-none d-md-block">
      <div className="d-flex align-items-center h-100">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {pathnames.map((value, index) => {
              const isLast = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              if (isLast || value === "edit") {
                return (
                  <li className="breadcrumb-item active text-capitalize" aria-current="page" key={to}>
                    {value.replace("_", " ")}
                  </li>
                );
              } else {
                return (
                  <li className="breadcrumb-item text-capitalize" key={to}>
                    <Link to={to}>{value.replace("_", " ")}</Link>
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumbs;
