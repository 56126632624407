import routes from "operations/routing/routes";

export const GALLERY_QUERY_OPTION = { page: 1, limit: 102, type: "image" };
export const VIDEO_QUERY_OPTION = { page: 1, limit: 30, type: "video" };
// export const IMAGE_PLACEHOLDER = placeholder;

export const ADMIN_PERMISSIONS = (permission) => {
  let permissions = {
    dashboard: true,
  };

  permission?.map((x) => {
    permissions[x] = true;
  });

  return {
    hotel: {
      permissions: {
        dashboard: true,
        orders: true,
        finance: true,
        // financeInvoice: true,
        financeHotels: true,
      },
      disabled: {},
      redirect: routes.dashboard.base,
    },
    branch: {
      permissions: {
        orders: {
          edit: true,
          list: true,
        },
        finance: true,
        financeBranches: true,
      },
      disabled: {},
      redirect: routes.order.base,
    },
    admin: {
      permissions: true,
      disabled: {
        // finance: true,
      },
      redirect: routes.order.base,
    },
    // admin: {
    //     permissions,
    //     disabled: {

    //     },
    //     redirect: routes.dashboard.base,
    // },
    super_admin: {
      permissions: true,
      disabled: {},
      redirect: routes.dashboard.base,
    },
    // admin: true,
  };
};
