import styles from "./GalleryComponent.module.scss";
import placeholder from "assets/images/placeholder.png";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import PdfIcon from "assets/images/pdf_icon.png";

const GalleryComponent = ({ description, selectedPhoto, handleModal, label, isRequired, Controller, name, control, errors, register, disables }) => {
  const newRegister = register ? register(name) : {};
  const [t] = useTranslation();

  const renderContent = (value) => {
    const isPdf = value?.mimetype?.startsWith("application/pdf") || selectedPhoto?.mimetype?.startsWith("application/pdf");
    const isImage = value?.mimetype?.startsWith("image/") || selectedPhoto?.mimetype?.startsWith("image/");
    // const isVideo = value?.mimetype || selectedPhoto?.mimetype?.startsWith("video/");
    const isVideo =
      (value?.mimetype && value.mimetype.startsWith("video/")) ||
      (selectedPhoto?.mimetype && selectedPhoto.mimetype.startsWith("video/")) ||
      (value?.path && value.path.endsWith(".mp4")) ||
      (typeof value === "string" && value.endsWith(".mp4")) ||
      (selectedPhoto?.path && selectedPhoto.path.endsWith(".mp4"));

    const src = value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder;

    if (isPdf) {
      return <img src={PdfIcon} alt="thumbnail" style={{ width: "150px" }} />;
    } else if (isImage) {
      return <img src={src} alt="thumbnail" />;
    } else if (isVideo) {
      return <video src={src} controls />;
    } else {
      return <img src={src} alt="thumbnail" />;
    }
  };

  return (
    <div className={styles.galleryComponent}>
      <div className={styles.titleWrapper}>
        <label htmlFor="image">
          {compareTranslate(t, label || "image")}
          {isRequired && " *"}
          {description && <small>{description}</small>}
        </label>
        {handleModal && (
          <button type="button" className={`${styles.button} btn btn-success btn-sm btn btn-primary`} onClick={handleModal}>
            <i className="mdi mdi-plus" />
          </button>
        )}
      </div>
      {errors && (
        <p className="error-text mt-1">{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</p>
      )}
      <Controller
        name={name}
        control={control}
        register={newRegister}
        render={({ field: { value } }) => <div className={styles.thumbnailWrapper}>{renderContent(value)}</div>}
      />
    </div>
  );
};

export default GalleryComponent;
