import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  description: "description",
  descAr: "descAr",
  descNl: "descNl",
  descFr: "descFr",
  descDe: "descDe",
  descHi: "descHi",
  descId: "descId",
  descIt: "descIt",
  descRu: "descRu",
  descEs: "descEs",
  descTr: "descTr",
  subtitle: "subTitle",
  subAr: "subAr",
  subNl: "subNl",
  subFr: "subFr",
  subDe: "subDe",
  subHi: "subHi",
  subId: "subId",
  subIt: "subIt",
  subRu: "subRu",
  subEs: "subEs",
  subTr: "subTr",
  calories: "calories",
  minutes: "minutes",
};

export const validation = (isRequired) => {
  return isRequired
    ? yup.object().shape({
        [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
        [fieldNames.calories]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.calories)).label(fieldNames.calories),
        [fieldNames.minutes]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.minutes)).label(fieldNames.minutes),
        [fieldNames.descAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descAr)).label(fieldNames.descAr),
        [fieldNames.descNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descNl)).label(fieldNames.descNl),
        [fieldNames.descFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descFr)).label(fieldNames.descFr),
        [fieldNames.descDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descDe)).label(fieldNames.descDe),
        [fieldNames.descHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descHi)).label(fieldNames.descHi),
        [fieldNames.descId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descId)).label(fieldNames.descId),
        [fieldNames.descIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descIt)).label(fieldNames.descIt),
        [fieldNames.descRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descRu)).label(fieldNames.descRu),
        [fieldNames.descEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descEs)).label(fieldNames.descEs),
        [fieldNames.descTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descTr)).label(fieldNames.descTr),

        [fieldNames.subtitle]: yup.string().optional().label(fieldNames.subtitle),
        [fieldNames.subAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subAr)).label(fieldNames.subAr),
        [fieldNames.subNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subNl)).label(fieldNames.subNl),
        [fieldNames.subFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subFr)).label(fieldNames.subFr),
        [fieldNames.subDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subDe)).label(fieldNames.subDe),
        [fieldNames.subHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subHi)).label(fieldNames.subHi),
        [fieldNames.subId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subId)).label(fieldNames.subId),
        [fieldNames.subIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subIt)).label(fieldNames.subIt),
        [fieldNames.subRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subRu)).label(fieldNames.subRu),
        [fieldNames.subEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subEs)).label(fieldNames.subEs),
        [fieldNames.subTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.subTr)).label(fieldNames.subTr),
      })
    : yup.object().shape({
        [fieldNames.description]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.description)).label(fieldNames.description),
        [fieldNames.calories]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.calories)).label(fieldNames.calories),
        [fieldNames.minutes]: yup.number().required(ERRORS.pleaseEnterYour(fieldNames.minutes)).label(fieldNames.minutes),
        [fieldNames.descAr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descAr)).label(fieldNames.descAr),
        [fieldNames.descNl]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descNl)).label(fieldNames.descNl),
        [fieldNames.descFr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descFr)).label(fieldNames.descFr),
        [fieldNames.descDe]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descDe)).label(fieldNames.descDe),
        [fieldNames.descHi]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descHi)).label(fieldNames.descHi),
        [fieldNames.descId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descId)).label(fieldNames.descId),
        [fieldNames.descIt]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descIt)).label(fieldNames.descIt),
        [fieldNames.descRu]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descRu)).label(fieldNames.descRu),
        [fieldNames.descEs]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descEs)).label(fieldNames.descEs),
        [fieldNames.descTr]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.descTr)).label(fieldNames.descTr),

        [fieldNames.subtitle]: yup.string().optional().label(fieldNames.subtitle),
        [fieldNames.subAr]: yup.string().optional().label(fieldNames.subAr),
        [fieldNames.subNl]: yup.string().optional().label(fieldNames.subNl),
        [fieldNames.subFr]: yup.string().optional().label(fieldNames.subFr),
        [fieldNames.subDe]: yup.string().optional().label(fieldNames.subDe),
        [fieldNames.subHi]: yup.string().optional().label(fieldNames.subHi),
        [fieldNames.subId]: yup.string().optional().label(fieldNames.subId),
        [fieldNames.subIt]: yup.string().optional().label(fieldNames.subIt),
        [fieldNames.subRu]: yup.string().optional().label(fieldNames.subRu),
        [fieldNames.subEs]: yup.string().optional().label(fieldNames.subEs),
        [fieldNames.subTr]: yup.string().optional().label(fieldNames.subTr),
      });
};
