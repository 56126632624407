import Admin from "containers/Admin";
import Ai from "containers/Ai";
import AiQa from "containers/AiQa";
import BaseDashboard from "containers/BaseDashboard";
import Cardio from "containers/Cardio";
import Dashboard from "containers/Dashboard";
import GuideCategory from "containers/GuideCategory";
import Guides from "containers/Guides";
import Ingredients from "containers/Ingredients";
import RecipeCategory from "containers/RecipeCategory";
import Recipes from "containers/Recipes";
import Strength from "containers/Strength";
import { PERMISSIONS } from "enumerations";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import routes from "./routes";
import ServingUnit from "containers/ServingUnit";
import PremiumPlan from "containers/PremiumPlan";
import Users from "containers/Users";
import Subscriptions from "containers/Subscriptions";

const Buttons = lazy(() => import("containers/basic-ui/Buttons"));
const Dropdowns = lazy(() => import("containers/basic-ui/Dropdowns"));
const Typography = lazy(() => import("containers/basic-ui/Typography"));
const BasicElements = lazy(() => import("containers/form-elements/BasicElements"));
const BasicTable = lazy(() => import("containers/tables/BasicTable"));
const Mdi = lazy(() => import("components/icons/Mdi"));
const Error404 = lazy(() => import("containers/error-pages/Error404"));
const Error500 = lazy(() => import("containers/error-pages/Error500"));

const routing = [
  { path: "/", exact: true, element: <Navigate to={routes.admin.base} /> },

  {
    path: routes.dashboard,
    element: <Dashboard />,
    name: "dashboard",
    permission: true,
    // permissions: ["ALL"],
  },

  {
    path: "/base-dashboard",
    element: <BaseDashboard />,
    name: "base-dashboard",
    permission: true,
    // permissions: ["ALL"],
  },

  // ------------------------- new ones-------------------------------
  { path: routes.admin.all, element: <Admin />, permission: true, name: "admins" },
  { path: routes.cardio.all, element: <Cardio />, permission: true, name: "cardio" },
  { path: routes.strength.all, element: <Strength />, permission: true, name: "strengths" },
  { path: routes.recipeCategory.all, element: <RecipeCategory />, permission: true, name: "recipeCategories" },
  { path: routes.servingUnit.all, element: <ServingUnit />, permission: true, name: "servingUnit" },
  { path: routes.recipes.all, element: <Recipes />, permission: true, name: "recipes" },
  { path: routes.guideCategory.all, element: <GuideCategory />, permission: true, name: "guideCategories" },
  { path: routes.guides.all, element: <Guides />, permission: true, name: "guides" },
  { path: routes.aiQa.all, element: <AiQa />, permission: true, name: "aiQas" },
  { path: routes.ai.all, element: <Ai />, permission: true, name: "ais" },
  { path: routes.ingredients.all, element: <Ingredients />, permission: true, name: "ingredients" },
  { path: routes.premiumPlan.all, element: <PremiumPlan />, permission: true, name: "premiumPlans" },
  { path: routes.users.all, element: <Users />, permission: true, name: "users" },
  { path: routes.subscriptions.all, element: <Subscriptions />, permission: true, name: "subscriptions" },
  // ------------------------- new ones-------------------------------
  // ------------------------- BASICS-------------------------------
  { path: "/basic-ui/buttons", element: <Buttons /> },
  { path: "/basic-ui/dropdowns", element: <Dropdowns /> },
  { path: "/basic-ui/typography", element: <Typography /> },
  { path: "/form-Elements/basic-elements", element: <BasicElements /> },
  { path: "/tables/basic-table", element: <BasicTable /> },
  { path: "/icons/mdi", element: <Mdi />, permissions: [PERMISSIONS.USER] },
  { path: "/error-pages/error-404", element: <Error404 /> },
  { path: "/error-pages/error-500", element: <Error500 /> },
];

export default routing;
