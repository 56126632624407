import swal from "sweetalert";

export const confirmAndExecuteAction = ({ data, status, name, deleteMutation, changeStatusMutation }) => {
  swal({
    title: "Are you sure?",
    text: `You want to ${status?.label?.toLowerCase()} this ${name}`,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willBe) => {
    if (willBe) {
      if (status.label === "delete") deleteMutation.mutate({ id: data });
      else changeStatusMutation.mutate({ ids: data, isActive: status.value });
    }
  });
};
