import React from "react";
import { useQuery } from "react-query";

export function useQueryCustom({ name, url, params, onSuccess, enabled, ...prop }) {
  const toastId = React.useRef(null);
  return useQuery([name, params ? params : { limit: 5, sort: "desc", page: 1 }], url, {
    onSuccess,
    // onError: (error) => {
    //   const changedError = changeError(error);
    //   toast.update(toastId.current, { type: toast.TYPE.WARNING, autoClose: 5000, render: "Error" });
    //   Object.entries(changedError).map(([key, value]) => {
    //     return toastify.error({ title: value.message });
    //   });
    // },
    enabled: enabled,
    ...prop,
  });
}
