import FieldText from "components/FieldText";
import { Button } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Tags = ({ name, control, setValue, watch, newValue }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const newTagValue = watch(newValue);

  const addTag = () => {
    if (newTagValue.trim() !== "") {
      // Check if the tag is not empty
      append({ value: newTagValue.trim() }); // Append the string value of the tag
      setValue(newValue, ""); // Clear the input field
    }
  };

  return (
    <div className="col-12 border p-4 mt-4">
      <h2>{t(name)}</h2>
      <div className="mt-4">
        <FieldText required name={newValue} placeholder={name} control={control} style={{ flex: 1 }} />
        <Button className="d-flex align-items-center mt-2 ml-auto" size="sm" onClick={addTag} disabled={!newTagValue}>
          <div className="gallery-add-title">{t("add")}</div>
          <div className="ml-1">+</div>
        </Button>
      </div>
      <div className="mt-5 col-12 d-flex">
        {fields.map((tag, index) => (
          <div className="d-flex ">
            <span className="badge badge-light d-flex mr-2 align-items-center text-dark" style={{ fontSize: "1rem" }}>
              {tag.value}
              <button type="button" className="close ml-2" aria-label="Dismiss" onClick={() => remove(index)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
