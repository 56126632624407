import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiCall, onSuccessMutation } from "utils/apiHelper";
import { confirmAndExecuteAction } from "utils/confirmAndExcuteAction";
import { createAddButton } from "utils/createAddButton";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { tableData } from "./tableInfo";

const List = () => {
  const { t } = useTranslation();
  const [checkList, setCheckList] = useState();
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const QueryString = useQueryString({ sort: "desc" });
  const { page, limit } = QueryString;

  // ------------------------------------------------------- Getting table's data
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: () => apiCall("get", api[API_NAME].list, QueryString),
    params: { ...QueryString },
  });
  // ---------------------------------------------- Delete table item
  const deleteMutation = useMutationCustom({
    name: `${API_NAME}_delete`,
    url: (params) => apiCall("delete", api[API_NAME].delete + "/" + params.id),
    invalidQuery: `${API_NAME}_get`,
    onSuccess: () => onSuccessMutation(),
  });
  // ---------------------------------------------- Change item status
  const changeStatusMutation = useMutationCustom({
    name: `${API_NAME}_changeStatus`,
    url: (params) => apiCall("patch", api[API_NAME].changeStatus, params),
    invalidQuery: `${API_NAME}_get`,
    onSuccess: () => onSuccessMutation(),
  });
  // ---------------------------------------------- action for table delete button
  const clickAction = ({ data, status }) => {
    const newData = data.map((x) => x._id);
    confirmAndExecuteAction({ data: newData?.[0], status, name: COMPONENT_NAMES[0], deleteMutation, changeStatusMutation });
  };
  // ---------------------------------------------- action for table checkBox
  const handleAction = (status) => {
    const data = Object.keys(checkList);
    confirmAndExecuteAction({ data, status, name: COMPONENT_NAMES[0], deleteMutation, changeStatusMutation });
  };
  const tableOptions = tableData({ onClick: clickAction, newRoute });

  return (
    <div>
      <PageTitle
        title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })}
        button={createAddButton({ title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }), link: newRoute.base + newRoute.add })}
      />
      <TableComponent
        {...{
          QueryString,
          data: data?.data?.result,
          tableOptions,
          loading: isLoading,
          search: {
            placeholder: t("search"),
            defaultValue: QueryString.searchText,
          },
          title: `${COMPONENT_NAMES[1]}`,
          count: data?.data?.total,
          pagination: {
            activePage: page,
            pages: Math.ceil(data?.data?.total / limit),
            limited: 2,
          },
          actions: {
            onClick: handleAction,
          },
          status: STATUSES,
        }}
      />
    </div>
  );
};

export default List;
